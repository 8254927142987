import { types } from 'react-bricks/frontend'

import bricks from './bricks'
import { RemixLink } from './bricks/atoms'
import pageTypes from './pageTypes'

const adminMenu = [
  {
    label: 'Editor',
    path: '/admin/editor'
  },
  {
    label: 'Playground',
    path: '/admin/playground'
  }
]

const editorMenu = [
  {
    label: 'Artisan Cove Editor',
    path: '/admin/editor'
  },
  {
    label: 'Preview',
    path: '/preview'
  }
]

const config: types.ReactBricksConfig = {
  // Credentials
  appId: '', //process.env.REACT_BRICKS_APP_ID as string || '',
  apiKey: '', //process.env.REACT_BRICKS_API_KEY as string || '',

  // CMS Content Blocks, Page Types, Custom fields
  pageTypes,
  bricks,
  customFields: [],

  // Environment Settings
  logo: '/images/admin-icon-420x50.png',
  loginUI: {
    sideImage: '/images/admin.jpg',
    logo: '/images/admin-icon-420x50.png',
    logoWidth: 100,
    welcomeText: 'Artisan Cove Admin'
  },
  previewPath: '/preview',
  renderLocalLink: RemixLink,
  navigate: () => {},
  getAdminMenu: ({ isAdmin }) => (isAdmin ? adminMenu : editorMenu),
  useCssInJs: false,
  appRootElement: '#root',
  clickToEditSide: types.ClickToEditSide.BottomRight,
  enableAutoSave: true,
  disableSaveIfInvalidProps: false,
  enablePreview: true
}

export default config
