import clsx from 'clsx'
import type { types } from 'react-bricks/frontend'
import { Repeater } from 'react-bricks/frontend'

import { BackgroundImage } from '~/components/BackgroundImage'
import { Container } from '~/components/Container'
import type { ColorValue, ReactBricksImage } from '~/react-bricks/brick-types'
import { Copy, Headline, Subhead } from '~/react-bricks/bricks/atoms'
import {
  bgColorSideEditProps,
  bgImageBlendModeSideEditProps,
  bgImageSideEditProps,
  bgOpacitySideEditProps,
  headlineColorSideEditProps,
  subheadColorSideEditProps,
  textColorSideEditProps
} from '~/react-bricks/layoutSideProps'

//=============================
// Local Types
//=============================
type FeatureAlternatingProps = {
  bgImage?: ReactBricksImage
  bgImageBlendMode?: string
  bgOpacity?: number
  bgColor?: ColorValue
  headlineColor?: ColorValue
  headlineSize?: string
  subheadColor?: ColorValue
  textColor?: ColorValue
}

//=============================
// Brick
//=============================
const FeatureAlternating: types.Brick<FeatureAlternatingProps> = ({
  bgImage,
  bgImageBlendMode,
  bgOpacity,
  bgColor,
  headlineColor,
  headlineSize,
  subheadColor,
  textColor
}) => {
  return (
    <section className={clsx(bgColor?.className ?? '', 'relative')}>
      <div aria-hidden="true" className="absolute inset-0 h-full w-full overflow-hidden">
        {bgImage && (
          <BackgroundImage
            bgImage={bgImage}
            bgImageBlendMode={bgImageBlendMode}
            bgOpacity={bgOpacity}
          />
        )}
      </div>
      <div className="relative mx-auto max-w-2xl px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
        <div className="mx-auto max-w-3xl text-center">
          <Subhead color={subheadColor} className="mb-6" />
          <Headline color={headlineColor} size={headlineSize} className="mb-6" />
          <Container size="max-w-prose" className="!px-0">
            <Copy color={textColor} className="mt-4" />
          </Container>
        </div>

        <div className="mt-16 space-y-16">
          <Repeater propName="items" />
        </div>
      </div>
    </section>
  )
}

//=============================
// Brick Schema
//=============================
FeatureAlternating.schema = {
  name: 'feature-alternating',
  label: 'Feature Alternating',
  getDefaultProps: () => ({
    headline: 'Lorem Ipsum Dolor Sit Amet',
    subhead: 'Turpis lectus tempor urna',
    copy: 'Vestibulum vel metus eget tellus interdum convallis. Phasellus accumsan, nibh vitae semper maximus, turpis lectus tempor urna, at placerat felis nisi sit amet ligula.',
    items: [
      {
        subhead: 'Lorem ipsum dolor sit amet',
        copy: 'Vestibulum vel metus eget tellus interdum convallis. Phasellus accumsan, nibh vitae semper maximus, turpis lectus tempor urna, at placerat felis nisi sit amet ligula.'
      }
    ]
  }),
  repeaterItems: [
    {
      name: 'items',
      itemType: 'feature-alternating-item',
      itemLabel: 'Feature Item',
      min: 1
    }
  ],
  sideEditProps: [
    {
      groupName: 'Background Image',
      defaultOpen: false,
      props: [bgImageSideEditProps, bgImageBlendModeSideEditProps, bgOpacitySideEditProps]
    },
    {
      groupName: 'Colors',
      defaultOpen: true,
      props: [
        bgColorSideEditProps,
        headlineColorSideEditProps,
        subheadColorSideEditProps,
        textColorSideEditProps
      ]
    }
  ]
}

export default FeatureAlternating
