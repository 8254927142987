import clsx from 'clsx'
import type { types } from 'react-bricks/frontend'
import { Repeater } from 'react-bricks/frontend'

import { BackgroundImage } from '~/components/BackgroundImage'
import { Container } from '~/components/Container'
import type { ColorValue, ReactBricksImage } from '~/react-bricks/brick-types'
import { Copy, Headline, Subhead } from '~/react-bricks/bricks/atoms'
import {
  bgColorSideEditProps,
  bgImageBlendModeSideEditProps,
  bgImageSideEditProps,
  bgOpacitySideEditProps,
  columnsSideEditProps,
  headlineColorSideEditProps,
  subheadColorSideEditProps,
  textColorSideEditProps
} from '~/react-bricks/layoutSideProps'

//=============================
// Local Types
//=============================
type FeatureTwoColumnProps = {
  bgImage?: ReactBricksImage
  bgImageBlendMode?: string
  bgOpacity?: number
  bgColor?: ColorValue
  subheadColor?: ColorValue
  headlineColor?: ColorValue
  textColor?: ColorValue
  columns?: string
}

//=============================
// Brick
//=============================
const FeatureTwoColumn: types.Brick<FeatureTwoColumnProps> = ({
  bgImage,
  bgImageBlendMode,
  bgOpacity,
  bgColor,
  subheadColor,
  headlineColor,
  textColor,
  columns
}) => {
  return (
    <section className={clsx(bgColor?.className ?? '', textColor?.className ?? '', 'relative')}>
      {bgImage && (
        <BackgroundImage
          bgImage={bgImage}
          bgImageBlendMode={bgImageBlendMode}
          bgOpacity={bgOpacity}
        />
      )}
      <Container className="relative z-[1] py-24 sm:py-32">
        <div className="items-center text-center">
          <Subhead color={subheadColor} />
          <Headline color={headlineColor} />
          <Container size="max-w-prose">
            <Copy color={textColor} className="mt-4" />
          </Container>
        </div>
        <Repeater
          propName="image-cards"
          renderWrapper={(items) => (
            <div className={clsx(columns, 'mt-16 grid gap-y-16 md:gap-x-8')}>{items}</div>
          )}
        />
      </Container>
    </section>
  )
}

//=============================
// Brick Schema
//=============================
FeatureTwoColumn.schema = {
  name: 'feature-two-column',
  label: 'Feature Cards',
  getDefaultProps: () => ({
    columns: 'grid-cols-1 md:grid-cols-2',
    'image-cards': [
      {
        title: 'Lorem ipsum dolor sit amet',
        copy: 'Aenean ac est sit amet dui mattis porta ut eu quam. Nam tincidunt diam non libero consequat, in rutrum enim aliquam.'
      },
      {
        title: 'Lorem ipsum dolor sit amet',
        copy: 'Aenean ac est sit amet dui mattis porta ut eu quam. Nam tincidunt diam non libero consequat, in rutrum enim aliquam.'
      }
    ]
  }),
  repeaterItems: [
    {
      name: 'image-cards',
      itemType: 'image-card',
      itemLabel: 'Image Card',
      min: 0,
      max: 8
    }
  ],
  sideEditProps: [
    {
      groupName: 'Settings',
      defaultOpen: true,
      props: [columnsSideEditProps]
    },
    {
      groupName: 'Backgroud Image',
      defaultOpen: false,
      props: [bgImageSideEditProps, bgImageBlendModeSideEditProps, bgOpacitySideEditProps]
    },
    {
      groupName: 'Colors',
      defaultOpen: true,
      props: [
        bgColorSideEditProps,
        subheadColorSideEditProps,
        headlineColorSideEditProps,
        textColorSideEditProps
      ]
    }
  ]
}

export default FeatureTwoColumn
