import clsx from 'clsx'
import { RichText } from 'react-bricks'

import type { ColorValue } from '~/react-bricks/brick-types'
import { richTextFeatures } from '~/react-bricks/layoutSideProps'

export type SubheadProps = {
  color?: ColorValue
  hide?: boolean | string
  name?: string
  className?: string
  overrideClasses?: string
  placeholder?: string
}

export const Subhead = ({
  color,
  hide = false,
  name = 'subhead',
  className = '',
  overrideClasses = '',
  placeholder = 'Type your subhead...'
}: SubheadProps) => {
  return (
    <>
      {!hide && (
        <RichText
          renderBlock={(props) => (
            <p
              className={clsx(
                className,
                color?.className || 'text-inherit',
                overrideClasses ||
                  'mb-4 font-mono text-xs font-medium uppercase tracking-widest lg:mb-6 2xl:text-sm'
              )}
            >
              {props.children}
            </p>
          )}
          placeholder={placeholder}
          propName={name}
          allowedFeatures={richTextFeatures}
        />
      )}
    </>
  )
}
