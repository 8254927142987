import clsx from 'clsx'
import type { types } from 'react-bricks/frontend'
import { Repeater } from 'react-bricks/frontend'

import { Container } from '~/components/Container'
import type { ColorValue } from '~/react-bricks/brick-types'
import { Copy, Headline, Subhead } from '~/react-bricks/bricks/atoms'
import {
  bgColorSideEditProps,
  headlineColorSideEditProps,
  headlineSizeSideEditProps,
  subheadColorSideEditProps,
  textColorSideEditProps
} from '~/react-bricks/layoutSideProps'

//=============================
// Local Types
//=============================
interface StatsProps {
  bgColor?: ColorValue
  headlineSize?: string
  subheadColor?: ColorValue
  headlineColor?: ColorValue
  textColor?: ColorValue
}

//=============================
// Brick
//=============================
const Stats: types.Brick<StatsProps> = ({
  bgColor,
  headlineSize,
  subheadColor,
  headlineColor,
  textColor
}) => {
  const statFontClass = 'text-4xl md:text-6xl'

  return (
    <section
      className={clsx(bgColor?.className ?? '', textColor?.className ?? '', 'relative w-full')}
    >
      <div className="relative flex items-center justify-center py-4 text-center sm:py-8">
        <Container size="max-w-7xl" className="flex flex-col w-full text-center">
          <Subhead color={subheadColor} className="mb-4 text-center" />
          <Headline color={headlineColor} size={headlineSize} className="w-full text-center" />
          <div>
            <Container size="max-w-prose" className="block !px-0">
              <Copy color={textColor} className="block w-full mt-4 text-center" />
            </Container>
          </div>
          <Repeater
            propName="stats"
            itemProps={{
              statFontClass,
              className: 'basis-1/2 md:basis-1/4 mb-8 text-center px-4'
            }}
            renderWrapper={(items) => (
              <div
                className={clsx(
                  'mt-10 flex w-full flex-wrap items-center justify-center text-center'
                )}
              >
                {items}
              </div>
            )}
          />
        </Container>
      </div>
    </section>
  )
}

//=============================
// Brick Schema
//=============================
Stats.schema = {
  name: 'stats',
  label: 'Stats',
  getDefaultProps: () => ({
    headline: 'Lorem Ipsum Dolor Sit Amet',
    subhead:
      'Vestibulum vel metus eget tellus interdum convallis. Phasellus accumsan, nibh vitae semper maximus, turpis lectus tempor urna, at placerat felis nisi sit amet ligula.',
    headlineSize: '5xl',
    bgOpacity: 1
  }),
  repeaterItems: [
    {
      name: 'stats',
      itemType: 'stat',
      itemLabel: 'Stat',
      min: 0,
      max: 8
    }
  ],
  sideEditProps: [
    {
      groupName: 'Settings',
      defaultOpen: false,
      props: [headlineSizeSideEditProps]
    },
    {
      groupName: 'Colors',
      defaultOpen: true,
      props: [
        bgColorSideEditProps,
        subheadColorSideEditProps,
        headlineColorSideEditProps,
        textColorSideEditProps
      ]
    }
  ]
}

export default Stats
