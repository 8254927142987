import clsx from 'clsx'
import { hexToCSSFilter } from 'hex-to-css-filter'
import { useEffect, useState } from 'react'
import type { types } from 'react-bricks/frontend'
import { Image, RichText } from 'react-bricks/frontend'

import type { ColorValue } from '~/react-bricks/brick-types'
import {
  maskSideEditProps,
  placeholderHeadline,
  placeholderSubhead,
  richTextFeatures
} from '~/react-bricks/layoutSideProps'

//=============================
// Local Types
//=============================
type IncentiveProps = {
  imageMask?: string
  iconColor?: ColorValue
  index?: number
}

//=============================
// Brick
//=============================
export const Incentive: types.Brick<IncentiveProps> = ({ imageMask, iconColor, index }) => {
  const [imageFilter, setImageFilter] = useState<string>()

  useEffect(() => {
    const { filter } = hexToCSSFilter(iconColor?.color || '#ffffff')
    setImageFilter(filter.replace(';', ''))
  }, [iconColor?.color])

  return (
    <div key={index ?? ''} className="text-center ">
      <div className="sm:flex-shrink-0">
        <div className="relative flow-root w-full overflow-hidden rounded-lg">
          <Image
            propName="image"
            alt="Artisan Cove Content"
            imageStyle={{ filter: imageFilter }}
            imageClassName={clsx(imageMask, 'mx-auto h-16 w-16 lg:h-20 lg:w-20 ')}
            aspectRatio={1}
          />
        </div>
      </div>
      <div className="mt-3 lg:mt-4">
        <RichText
          renderBlock={(props) => (
            <h4 className="text-base font-semibold text-inherit">{props.children}</h4>
          )}
          placeholder="Title..."
          propName="title"
          allowedFeatures={richTextFeatures}
        />
        <RichText
          renderBlock={(props) => <p className="mt-2 text-sm text-inherit">{props.children}</p>}
          placeholder="Copy..."
          propName="copy"
          allowedFeatures={richTextFeatures}
        />
      </div>
    </div>
  )
}

//=============================
// Brick Schema
//=============================
Incentive.schema = {
  name: 'incentive',
  label: 'Incentive',
  getDefaultProps: () => ({
    title: placeholderHeadline,
    copy: placeholderSubhead
  }),
  hideFromAddMenu: true,
  sideEditProps: [maskSideEditProps]
}
