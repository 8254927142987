import clsx from 'clsx'
import { types } from 'react-bricks/frontend'

import type { ReactBricksImage } from '~/react-bricks/brick-types'

//=============================
// Local Types
//=============================
type ImageRepeaterProps = {
  image: ReactBricksImage
  aspectRatio?: string
  className?: string
}

//=============================
// Brick
//=============================
export const ImageRepeater: types.Brick<ImageRepeaterProps> = ({
  image,
  className,
  aspectRatio
}) => {
  return (
    <img
      src={image?.src ?? 'https://placekitten.com/1600/900'}
      srcSet={image?.srcSet ?? ''}
      alt={image?.alt ?? ''}
      sizes="100vw"
      className={clsx(className, aspectRatio, ' h-auto w-full object-cover object-center')}
    />
  )
}

//=============================
// Brick Schema
//=============================
ImageRepeater.schema = {
  name: 'image-repeater',
  label: 'ImageRepeater',
  getDefaultProps: () => ({}),
  hideFromAddMenu: true,
  sideEditProps: [
    {
      name: 'image',
      label: 'Image',
      type: types.SideEditPropType.Image,
      imageOptions: {
        maxWidth: 4096
      }
    }
  ]
}
