import type { LinksFunction, LoaderFunction, MetaFunction } from '@remix-run/node'
import { Outlet, Scripts, useCatch } from '@remix-run/react'
import React from 'react'
import type { types } from 'react-bricks'
import { fetchPage } from 'react-bricks'
import invariant from 'tiny-invariant'

import { CatchBoundaryContent } from '~/components/boundaries'
import { Document } from '~/components/Document'
import {
  DEFAULT_DESCRIPTION,
  DEFAULT_OG_IMAGE,
  DEFAULT_TITLE,
  DEFAULT_TWITTER_IMAGE,
  DEFAULT_URL
} from '~/constants'
import styles from '~/styles/app.css'

// useLayoutEffect shim
// eslint-disable-next-line @typescript-eslint/no-unused-vars
if (typeof window === 'undefined') React.useLayoutEffect = () => {}

export const meta: MetaFunction = () => ({
  title: DEFAULT_TITLE,
  description: DEFAULT_DESCRIPTION,
  'og:title': DEFAULT_TITLE,
  'og:url': DEFAULT_URL,
  'og:description': DEFAULT_DESCRIPTION,
  'og:image': DEFAULT_OG_IMAGE,
  'twitter:image': DEFAULT_TWITTER_IMAGE,
  'twitter:card': 'summary_large_image',
  'twitter:title': DEFAULT_TITLE,
  'twitter:description': DEFAULT_DESCRIPTION
})

export const links: LinksFunction = () => [
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  { rel: 'preconnect', href: 'https://fonts.gstatic.com' },
  { rel: 'stylesheet', href: styles },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400;500;600&family=IBM+Plex+Sans+Condensed:wght@200;300;500;600&display=swap'
  },
  { rel: 'manifest', href: '/manifest.json' },
  { rel: 'author', href: '/humans.txt' }
]

export type RootLoaderData = {
  appId: string
  apiKey: string
  gaTrackingId: string | undefined
  headerPage?: types.Page | null
  footerPage?: types.Page | null
}

export const loader: LoaderFunction = async (): Promise<RootLoaderData> => {
  invariant(process.env.REACT_BRICKS_API_KEY, 'ReactBricks API key required')
  invariant(process.env.REACT_BRICKS_APP_ID, 'ReactBricks App ID required')
  invariant(process.env.GA_TRACKING_ID, 'Google Analytics Property ID required')

  const apiKey = (process.env.REACT_BRICKS_API_KEY as string) || ''
  const appId = (process.env.REACT_BRICKS_APP_ID as string) || ''
  const gaTrackingId = (process.env.GA_TRACKING_ID as string) || ''

  if (!apiKey || !appId) {
    throw new Error('Missing React Bricks credentials in .env file')
  }

  const headerPage = await fetchPage('header', apiKey)
  const footerPage = await fetchPage('footer', apiKey)
  return { appId, apiKey, gaTrackingId, footerPage, headerPage }
}

export default function App() {
  return (
    <Document>
      <Outlet />
    </Document>
  )
}

export function CatchBoundary() {
  const caught = useCatch()
  console.error(`${caught.status} ${caught.statusText}`)

  switch (caught.status) {
    case 400:
    case 401:
    case 403:
    case 404:
      return <CatchBoundaryContent code={caught.status} />

    default:
      throw new Error(`Unexpected caught response with status: ${caught.status}`)
  }
}

export function ErrorBoundary({ error }: { error: unknown }) {
  console.error(error)
  return (
    <html>
      <head>
        <title>Oh no!</title>
      </head>
      <body>
        An unknown error occurred
        <Scripts />
      </body>
    </html>
  )
}
