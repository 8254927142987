import clsx from 'clsx'

import { SocialIcon } from './components/SocialIcon'

export type LinkItem = {
  label: string
  url: string
  description?: string
  icon?: String
  subnav?: LinkItem[]
}

export const SOCIAL_LINKS: LinkItem[] = [
  {
    label: 'Facebook',
    url: 'https://www.facebook.com/artisancovelivework/'
  },
  {
    label: 'Instagram',
    url: 'https://www.instagram.com/artisancovelivework/'
  },
  {
    label: 'Youtube',
    url: 'https://www.youtube.com/channel/UCbWw5LaIYgKyekR7K6Pfhyg'
  }
]

export const SocialIcons = ({ className }: { className?: string }) => {
  return (
    <>
      {SOCIAL_LINKS.map((link, index) => (
        <a
          key={link.label || index}
          href={link.url}
          className={clsx(className, 'text-inherit')}
          target="_blank"
          rel="noreferrer nofollow"
        >
          <span className="sr-only">{link.label}</span>
          <SocialIcon icon={link.label} className="w-6 h-6" />
        </a>
      ))}
    </>
  )
}
