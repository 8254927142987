const colors = require('tailwindcss/colors')

/*
  PROJECT CONVENTION NOTE:
  Using semantic class naming following DaisyUI, extending the Daisy 
  naming convention across classes to be use dynamically with the CMS
*/

/*
  MAIN COLOR CONFIG
    • Pair DaisyUI classes to Tailwind classes
    • The tailwind value is used as the color in Daisy UI
    • tailwindClass sccepts keys from THEME_PALETTE or the default Tailwind color names
    • themeClass values must be valid DaisyUI names
*/
const THEME_COLOR_CONFIG = [
  { themeClass: 'accent', tailwindClass: 'blue', default: 800 },
  { themeClass: 'error', tailwindClass: 'tangelo', default: 800 },
  { themeClass: 'primary', tailwindClass: 'pistachio', default: 800 },
  { themeClass: 'secondary', tailwindClass: 'opal', default: 800 },
  { themeClass: 'neutral', tailwindClass: 'orchid', default: 800 }
]

/*
  Set the classnames and color value to be injected
  into Tailwind extended colors 
*/
const COLOR_VARIANT_CONFIG = [
  {
    name: '-lightest',
    value: 50
  },
  {
    name: '-lighter',
    value: 100
  },
  {
    name: '-light',
    value: 300
  },
  { name: '', value: 400 },
  {
    name: '-dark',
    value: 700
  },
  {
    name: '-darker',
    value: 800
  },
  {
    name: '-darkest',
    value: 900
  }
]

// Extend / Override the TailWind config with these colors
// Helpful --> https://huey.design/
const THEME_PALETTE = {
  blue: {
    50: '#e4e4e8',
    100: '#cbcbd2',
    200: '#b1b1bc',
    300: '#9999a7',
    400: '#818192',
    500: '#6a6a7b',
    600: '#555562',
    700: '#3f3f49',
    800: '#2b2b32',
    900: '#1a1a1e'
  },
  orchid: {
    50: '#e8e4e7',
    100: '#d1cad0',
    200: '#bab0b9',
    300: '#a496a2',
    400: '#8f7d8c',
    500: '#766774',
    600: '#5e525d',
    700: '#473d46',
    800: '#312b30',
    900: '#1c191c'
  },
  tangelo: {
    50: '#e7e4e3',
    100: '#cfcac7',
    200: '#b8b2ad',
    300: '#a19892',
    400: '#8b8079',
    500: '#736a64',
    600: '#5b544f',
    700: '#45403c',
    800: '#2f2b29',
    900: '#1b1918'
  },
  pistachio: {
    50: '#e3e6e2',
    100: '#c8cdc5',
    200: '#aeb5a9',
    300: '#949d8e',
    400: '#7b8674',
    500: '#666e60',
    600: '#51574c',
    700: '#3d433a',
    800: '#2a2e27',
    900: '#191b17'
  },
  opal: {
    50: '#e2e6e5',
    100: '#c5cdcc',
    200: '#a9b5b3',
    300: '#8e9d9b',
    400: '#748684',
    500: '#606e6c',
    600: '#4c5856',
    700: '#3a4341',
    800: '#272e2d',
    900: '#171b1a'
  }
}

/*
  Create colors object for Tailwind Config
*/
function createThemeColors() {
  const colorTheme = {}
  THEME_COLOR_CONFIG.forEach((item) => {
    const palette = item.tailwindClass in THEME_PALETTE ? THEME_PALETTE : colors
    for (const variant of COLOR_VARIANT_CONFIG) {
      colorTheme[`${item.themeClass}${variant.name}`] = palette[item.tailwindClass][variant.value]
    }
  })
  return colorTheme
}

/*
  Create color object for DaisyUI config
*/
function createDaisyThemeColors() {
  const colorTheme = {}
  THEME_COLOR_CONFIG.forEach((item) => {
    const palette = item.tailwindClass in THEME_PALETTE ? THEME_PALETTE : colors
    colorTheme[item.themeClass] = palette[item.tailwindClass][item.default]
  })
  return colorTheme
}

// TAILWIND COLORS
const THEME_COLORS = createThemeColors()

// DAISYUI COLORS
const DAISY_THEME_COLORS = createDaisyThemeColors()

module.exports = { THEME_COLORS, THEME_PALETTE, DAISY_THEME_COLORS }
