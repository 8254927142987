import clsx from 'clsx'
import { Image } from 'react-bricks'

export type ArticleImageProps = {
  propName?: string
  className?: string
  alt: string
  imageWidth?: string
}

export const ArticleImage = ({ propName, className, alt, imageWidth }: ArticleImageProps) => {
  return (
    <div className={clsx(imageWidth, 'w-full')}>
      <Image
        propName={propName || 'image'}
        alt={alt || 'image'}
        imageClassName={className}
        renderWrapper={({ children, width, height }) => (
          <div className={`w-full aspect-[${width}/${height}]`}>{children}</div>
        )}
      />
    </div>
  )
}

//=============================
// Brick Schema
//=============================
ArticleImage.schema = {
  name: 'article-image',
  label: 'Image',
  getDefaultProps: () => ({}),
  hideFromAddMenu: true,
  sideEditProps: []
}
