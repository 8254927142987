import clsx from 'clsx'
import { RichText } from 'react-bricks'

import type { ColorValue } from '~/react-bricks/brick-types'
import { richTextFeatures } from '~/react-bricks/layoutSideProps'
import { getFontSizeClass } from '~/utils/type'

export type HeadlineProps = {
  color?: ColorValue
  hide?: boolean | string
  name?: string
  className?: string
  size?: string
  placeholder?: string
}

export const Headline = ({
  color,
  hide = false,
  size = '',
  name = 'headline',
  className = '',
  placeholder = 'Type your headline...'
}: HeadlineProps) => {
  const sizeClass = getFontSizeClass(size)

  return (
    <>
      {!hide && (
        <RichText
          renderBlock={(props) => (
            <h2
              className={clsx(
                className,
                sizeClass,
                color?.className || 'text-inherit',
                'uppercase'
              )}
            >
              {props.children}
            </h2>
          )}
          placeholder={placeholder}
          propName={name}
          allowedFeatures={richTextFeatures}
        />
      )}
    </>
  )
}

// Brick Schema
//=============================
Headline.schema = {
  getDefaultProps: () => ({
    headline: 'Lorem Ipsum Dolor Sit Amet'
  })
}
