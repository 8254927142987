import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import clsx from 'clsx'
import { Repeater, types } from 'react-bricks/frontend'
import { SlArrowRight } from 'react-icons/sl'

import type { ContainerSizes } from '~/components/Container'
import { aspectRatioEditProps, verticalMarginSideEditProps } from '~/react-bricks/layoutSideProps'

//=============================
// Local Types
//=============================
type ImageCarouselProps = {
  containerWidth?: ContainerSizes
  verticalMargin?: string
  aspectRatio?: string
  autoplay?: boolean
}

//=============================
// Brick
//=============================
const ImageCarousel: types.Brick<ImageCarouselProps> = ({
  containerWidth,
  verticalMargin,
  aspectRatio,
  autoplay
}) => {
  return (
    <section className={clsx(containerWidth, verticalMargin, 'mx-auto')}>
      <div className={clsx(aspectRatio, 'w-full')}>
        <Splide
          tag="div"
          className="relative h-auto min-h-[10rem] w-full"
          options={{
            type: 'loop',
            rewind: true,
            pauseOnHover: true,
            resetProgress: false,
            autoplay
          }}
          hasTrack={false}
        >
          <SplideTrack>
            <Repeater
              propName="slides"
              itemProps={{ aspectRatio }}
              renderItemWrapper={(item) => <SplideSlide>{item}</SplideSlide>}
            />
          </SplideTrack>
          <div className="splide__progress">
            <div className="splide__progress__bar" />
          </div>
          <div className="splide__arrows">
            <button className="splide__arrow splide__arrow--prev !bg-black">
              <SlArrowRight
                className="!fill-white opacity-90"
                style={{ fill: 'white !important' }}
              />
            </button>
            <button className="splide__arrow splide__arrow--next !bg-black">
              <SlArrowRight
                className="!fill-white opacity-90"
                style={{ fill: 'white !important' }}
              />
            </button>
          </div>
        </Splide>
      </div>
    </section>
  )
}

//=============================
// Brick Schema
//=============================
ImageCarousel.schema = {
  name: 'ImageCarousel',
  label: 'ImageCarousel',
  getDefaultProps: () => ({
    containerWidth: 'max-w-container',
    aspectRatio: 'aspect-video',
    slides: [
      {
        src: 'https://dummyimage.com/1600x900/333/555'
      }
    ]
  }),
  repeaterItems: [
    {
      name: 'slides',
      itemType: 'image-carousel-slide',
      itemLabel: 'Add Slide',
      min: 1,
      max: 20
    }
  ],
  sideEditProps: [
    {
      name: 'autoplay',
      label: 'Autoplay',
      type: types.SideEditPropType.Boolean
    },
    {
      name: 'containerWidth',
      label: 'Container Width',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Select,
        options: [
          { label: 'Large', value: 'max-w-5xl' },
          { label: 'XL', value: 'max-w-6xl' },
          { label: '2XL', value: 'max-w-7xl' },
          { label: 'Full', value: 'max-w-container' }
        ]
      }
    },
    verticalMarginSideEditProps,
    aspectRatioEditProps
  ]
}

export default ImageCarousel
