import clsx from 'clsx'

export type ContainerSizes =
  | 'max-w-xs'
  | 'max-w-sm'
  | 'max-w-md'
  | 'max-w-lg'
  | 'max-w-xl'
  | 'max-w-2xl'
  | 'max-w-3xl'
  | 'max-w-4xl'
  | 'max-w-5xl'
  | 'max-w-6xl'
  | 'max-w-7xl'
  | 'max-w-min'
  | 'max-w-max'
  | 'max-w-fit'
  | 'max-w-prose'
  | 'max-w-container'
  | 'max-w-screen-sm'
  | 'max-w-screen-md'
  | 'max-w-screen-lg'
  | 'max-w-screen-xl'
  | 'max-w-screen-2xl'

export type ContainerProps = {
  className?: string
  children: React.ReactNode
  size?: ContainerSizes
}

export function Container({ children, className, size, ...props }: ContainerProps) {
  const sizeClass = size || 'max-w-7xl'
  return (
    <div className={clsx(sizeClass, className, 'mx-auto px-4')} {...props}>
      {children}
    </div>
  )
}
