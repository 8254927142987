import clsx from 'clsx'
import { useState } from 'react'
import type { types } from 'react-bricks/frontend'
import { useEffectOnce } from 'usehooks-ts'

import { Container } from '~/components/Container'
import { SocialIcons } from '~/components/socialIcons/SocialIcons'
import type { ColorValue } from '~/react-bricks/brick-types'
import { Copy, Subhead } from '~/react-bricks/bricks/atoms'
import {
  bgColorSideEditProps,
  subheadColorSideEditProps,
  textColorSideEditProps
} from '~/react-bricks/layoutSideProps'

//=============================
// Local Types
//=============================
type FooterProps = {
  bgColor?: ColorValue
  textColor?: ColorValue
  subheadColor?: ColorValue
}

//=============================
// Brick
//=============================
export const Footer: types.Brick<FooterProps> = ({ bgColor, textColor, subheadColor }) => {
  const [date, setDate] = useState<string>('2022')

  useEffectOnce(() => {
    const date = new Date().getFullYear().toString()
    setDate(date)
  })

  return (
    <footer
      className={clsx(
        bgColor?.className || 'bg-gray-900',
        textColor?.className || 'text-gray-300',
        'pt-8   md:pt-12'
      )}
    >
      <Container>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          <div>
            <Subhead name="footerSubhead1" color={subheadColor} />
            <Copy className="text-sm prose" name="footer1" />
          </div>
          <div>
            <Subhead name="footerSubhead2" color={subheadColor} />
            <Copy className="text-sm prose" name="footer2" />
          </div>
          <div>
            <Subhead name="footerSubhead3" color={subheadColor} />
            <Copy className="text-sm prose" name="footer3" />
          </div>
        </div>
        <div className="py-12 mx-auto text-xs uppercase md:flex md:items-center md:justify-between">
          <div className="flex justify-center space-x-6 md:order-2">
            <SocialIcons className="text-sm" />
          </div>
          <div className="mt-8 md:order-1 md:mt-0">
            <p className="text-center opacity-80 ">&copy; {date} Artisan Cove Live Work</p>
          </div>
        </div>
      </Container>
    </footer>
  )
}

//=============================
// Brick Schema
//=============================
Footer.schema = {
  name: 'footer',
  label: 'Footer',
  getDefaultProps: () => ({}),
  sideEditProps: [bgColorSideEditProps, subheadColorSideEditProps, textColorSideEditProps]
}
