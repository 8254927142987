import type { LinksFunction, MetaFunction } from '@remix-run/node'
import { Links, LiveReload, Meta, Scripts, ScrollRestoration } from '@remix-run/react'

import { ErrorPage } from '~/components/error'
import { MetaBase, MetaFavicon } from '~/components/meta'
import {
  DEFAULT_DESCRIPTION,
  DEFAULT_OG_IMAGE,
  DEFAULT_TITLE,
  DEFAULT_TWITTER_IMAGE,
  DEFAULT_URL
} from '~/constants'
import styles from '~/styles/app.css'

export const meta: MetaFunction = () => ({
  description: DEFAULT_DESCRIPTION,
  'og:title': DEFAULT_TITLE,
  'og:url': DEFAULT_URL,
  'og:description': DEFAULT_DESCRIPTION,
  'og:image': DEFAULT_OG_IMAGE,
  'twitter:image': DEFAULT_TWITTER_IMAGE,
  'twitter:card': 'summary_large_image',
  'twitter:title': DEFAULT_TITLE,
  'twitter:description': DEFAULT_DESCRIPTION
})

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: styles },
  { rel: 'manifest', href: '/manifest.json' },
  { rel: 'author', href: '/humans.txt' }
]

type CatchBoundaryContentProps = {
  code?: number | string
}

export const CatchBoundaryContent = ({ code }: CatchBoundaryContentProps) => {
  const isDevelopment = process.env.NODE_ENV === 'development'

  let message
  switch (code) {
    case 400:
      message = 'Bad Request'
    case 401:
      message = 'Unauthorized'
    case 403:
      message = 'Forbidden'
    case 404:
      message = 'Not Found'
    default:
      message = 'An error occurred'
  }

  const title = code ? `${code} | ${message}` : 'Oops...'

  return (
    <html lang="en" data-theme="artisancove">
      <head>
        <MetaBase />
        <MetaFavicon />
        <Meta />
        <title>{title}</title>
        <Links />
      </head>
      <body className="relative flex flex-col bg-base-100" id="root">
        <ErrorPage title={title} message={message} />
        <ScrollRestoration />
        <link rel="stylesheet" href="https://use.typekit.net/dhq1fxr.css" />
        <Scripts />
        {isDevelopment && <LiveReload />}
      </body>
    </html>
  )
}
