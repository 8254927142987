import { Link, useLocation } from '@remix-run/react'
import type { types } from 'react-bricks/frontend'
import { useAdminContext } from 'react-bricks/frontend'

export const RemixLink: types.RenderLocalLink = ({
  href,
  className,
  activeClassName,
  children
}) => {
  const location = useLocation()
  const { isAdmin } = useAdminContext()

  let anchorClassName = ''

  if (location.pathname === href) {
    anchorClassName = activeClassName || ''
  } else {
    anchorClassName = className || ''
  }

  if (isAdmin && !href.includes('playground') && !href.includes('editor')) {
    return <span className={anchorClassName}>{children}</span>
  } else {
    return (
      <Link to={href} prefetch="intent">
        <span className={anchorClassName}>{children}</span>
      </Link>
    )
  }
}
