import { RichText } from 'react-bricks'
import { FaExternalLinkAlt } from 'react-icons/fa'

import { RemixLink } from '~/react-bricks/bricks/atoms'
import { richTextFeaturesExtended } from '~/react-bricks/layoutSideProps'
import { isExternalURL } from '~/utils/url'

export type ArticleCopyProps = {}

export const ArticleCopy = () => {
  return (
    <div className="prose text-base leading-relaxed !text-current md:text-lg">
      <RichText
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        renderBlock={(props: any) => (
          <p {...props.attributes} className="">
            {props.children}
          </p>
        )}
        placeholder="Type your text..."
        propName="text"
        allowedFeatures={richTextFeaturesExtended}
        renderH1={({ children, attributes }) => (
          <span className="font-serif text-3xl md:text-4xl" {...attributes}>
            {children}
          </span>
        )}
        renderH2={({ children, attributes }) => (
          <span className="font-serif text-2xl md:text-3xl" {...attributes}>
            {children}
          </span>
        )}
        renderH3={({ children, attributes }) => (
          <span className="font-serif text-xl md:text-2xl" {...attributes}>
            {children}
          </span>
        )}
        renderH4={({ children, attributes }) => (
          <span className="font-serif text-lg md:text-xl" {...attributes}>
            {children}
          </span>
        )}
        renderH5={({ children, attributes }) => (
          <span className="text-base md:text-lg" {...attributes}>
            {children}
          </span>
        )}
        renderH6={({ children, attributes }) => (
          <span className="text-sm" {...attributes}>
            {children}
          </span>
        )}
        renderLink={({ href, children, attributes }) => {
          if (isExternalURL(href)) {
            return (
              <a href={href} className="underline text-inherit" {...attributes}>
                <span className="inline-block underline">{children}</span>
                <FaExternalLinkAlt className="ml-[3px] mr-2 inline-block text-[10px]" />
              </a>
            )
          }
          return (
            <RemixLink href={href} className="underline text-inherit" {...attributes}>
              {children}
            </RemixLink>
          )
        }}
      />
    </div>
  )
}

//=============================
// Brick Schema
//=============================
ArticleCopy.schema = {
  name: 'article-copy',
  label: 'Paragraph Copy',
  getDefaultProps: () => ({
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ac enim nec metus varius scelerisque. Duis sed molestie diam, vitae commodo mi. Aenean sit amet ultricies diam.'
  }),
  hideFromAddMenu: true,
  sideEditProps: []
}
