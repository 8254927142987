import { Popover, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Repeater } from 'react-bricks/frontend'
import { GiHamburgerMenu } from 'react-icons/gi'
import { MdClose } from 'react-icons/md'

import { Container } from '~/components/Container'
import { Logo } from '~/components/Logo'
import { RemixLink } from '~/react-bricks/bricks/atoms/'

//=============================
// Local Types
//=============================
// type HeaderProps = {}

//=============================
// Brick
//=============================
export const Header = () => {
  function NavLinks() {
    return <Repeater propName="navlinks" />
  }
  return (
    <header>
      <Popover className="relative">
        <Container className="flex items-center justify-between h-12 md:h-16">
          <RemixLink href="/">
            <Logo className="text-gray-800" />
          </RemixLink>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <GiHamburgerMenu className="w-6 h-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <nav className="hidden space-x-6 font-mono text-xs font-medium tracking-wider text-gray-700 uppercase md:flex">
            <NavLinks />
          </nav>
        </Container>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Panel
            focus
            className="absolute inset-x-0 top-0 z-50 p-2 transition origin-top transform md:hidden"
          >
            <div className="relative z-10 bg-white divide-y-2 rounded-lg shadow-lg divide-gray-50 ring-1 ring-black ring-opacity-5">
              <div className="px-5 pt-4 pb-8">
                <div className="flex items-center justify-between">
                  <div></div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close menu</span>
                      <MdClose className="w-6 h-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <nav className="flex flex-col space-y-6 font-mono text-lg font-medium tracking-wider text-gray-700 uppercase md:hidden">
                  <NavLinks />
                </nav>
              </div>
            </div>
            <div className="fixed inset-0 z-0 h-[100vh] w-full bg-black/50 backdrop-blur-xs"></div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </header>
  )
}

//=============================
// Brick Schema
//=============================
Header.schema = {
  name: 'header',
  label: 'Header',
  getDefaultProps: () => ({
    navlinks: [
      {
        label: 'Lorem Ipsum',
        link: '/'
      }
    ]
  }),
  repeaterItems: [
    {
      name: 'navlinks',
      itemType: 'navlink',
      itemLabel: 'Add Nav Item',
      min: 1,
      max: 7
    }
  ],
  sideEditProps: []
}
