import clsx from 'clsx'

import type { ReactBricksImage } from '~/react-bricks/brick-types'

type BackgroundImageProps = {
  bgImage?: ReactBricksImage
  bgImageBlendMode?: string
  bgOpacity?: number
}

export const BackgroundImage = ({ bgImage, bgImageBlendMode, bgOpacity }: BackgroundImageProps) => {
  if (!bgImage) {
    return <div>No image src</div>
  }
  return (
    <img
      aria-hidden="true"
      src={bgImage?.src ?? ''}
      srcSet={bgImage?.srcSet ?? ''}
      alt={bgImage?.alt ?? 'Featured'}
      sizes="100vw"
      className={clsx(
        bgImageBlendMode,
        'absolute top-0 bottom-0 left-0 right-0 z-[0] h-full w-full object-cover object-center'
      )}
      style={{ opacity: bgOpacity }}
    />
  )
}
