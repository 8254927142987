import clsx from 'clsx'
import { RichText } from 'react-bricks'

import type { ColorValue } from '~/react-bricks/brick-types'
import { richTextFeaturesExtended } from '~/react-bricks/layoutSideProps'

export type CopyProps = {
  color?: ColorValue
  hide?: boolean | string
  name?: string
  className?: string
  placeholder?: string
}

export const Copy = ({
  color,
  hide = false,
  name = 'copy',
  className = '',
  placeholder = 'Type your text...'
}: CopyProps) => {
  return (
    <>
      {!hide && (
        <RichText
          renderBlock={(props) => (
            <p className={clsx(className, color?.className || 'text-inherit', '!leading-relaxed')}>
              {props.children}
            </p>
          )}
          placeholder={placeholder}
          propName={name}
          allowedFeatures={richTextFeaturesExtended}
        />
      )}
    </>
  )
}
