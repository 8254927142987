import clsx from 'clsx'

type LogoProps = {
  className?: string
}

export const Logo = ({ className }: LogoProps) => {
  const LogoText = () => {
    return <span className="font-semibold tracking-wider uppercase">Artisan Cove</span>
  }

  return (
    <div
      className={clsx(
        className,
        'group relative overflow-hidden border-2 border-black px-3 py-2 hover:text-white'
      )}
    >
      <span className="absolute inset-0 translate-x-[-101%] bg-black transition-transform group-hover:translate-x-0"></span>
      <span className="absolute inset-0 flex items-center justify-center font-bold group-hover:translate-x-0">
        <LogoText />
      </span>
      <LogoText />
    </div>
  )
}
