import { types } from 'react-bricks'

import {
  bgColorOptions,
  btnColorOptions,
  gradientFromColorOptions,
  gradientToColorOptions,
  textColorOptions
} from '~/react-bricks/colors'

/* *******************************
 VARIABLES
******************************* */
export const placeholderHeadline = 'Lorem ipsum dolor sit amet'
export const placeholderSubhead = 'Suspendisse euismod velit id'
export const placeholderCopy =
  'Suspendisse nec egestas dui, quis cursus enim. In ut blandit leo. Vestibulum vestibulum velit quis eleifend sollicitudin. Phasellus commodo vel nibh nec efficitur. Curabitur facilisis dictum odio a commodo. Integer efficitur mi et eros fringilla facilisis. Maecenas posuere tincidunt fermentum'

export const blendModes = [
  { value: 'mix-blend-normal', label: 'Normal' },
  { value: 'mix-blend-multiply', label: 'Multiply' },
  { value: 'mix-blend-screen', label: 'Screen' },
  { value: 'mix-blend-overlay', label: 'Overlay' },
  { value: 'mix-blend-darken', label: 'Darken' },
  { value: 'mix-blend-lighten', label: 'Lighten' },
  { value: 'mix-blend-color', label: 'Color' },
  { value: 'mix-blend-luminosity', label: 'Luminosity' }
]

export const maskOptions = [
  { value: '', label: 'None' },
  { value: 'rounded-lg', label: 'Rounded Corners 1' },
  { value: 'rounded-2xl', label: 'Rounded Corners 2' },
  { value: 'mask mask-square', label: 'Square' },
  { value: 'mask mask-circle', label: 'Circle' },
  { value: 'mask mask-squircle', label: 'Squircle' },
  { value: 'mask mask-diamond', label: 'Diamond' },
  { value: 'mask mask-hexagon', label: 'Hexagon' },
  { value: 'mask mask-hexagon-2', label: 'Hexagon 2' },
  { value: 'mask mask-decagon', label: 'Decagon' },
  { value: 'mask mask-pentagon', label: 'Pentagon' },
  { value: 'mask mask-triangle', label: 'Triangle Up' },
  { value: 'mask mask-triangle-2', label: 'Triangle Down' },
  { value: 'mask mask-triangle-3', label: 'Triangle Left' },
  { value: 'mask mask-triangle-4', label: 'Triangle Right' }
]

export const sizes = [
  { value: 'xs', label: 'X-Small' },
  { value: 'sm', label: 'Small' },
  { value: 'md', label: 'Medium' },
  { value: 'lg', label: 'Large' },
  { value: 'xl', label: 'X-Large' },
  { value: '2xl', label: '2XL' },
  { value: '3xl', label: '3XL' },
  { value: '4xl', label: '4XL' },
  { value: '5xl', label: '5XL' },
  { value: '6xl', label: '6XL' },
  { value: '7xl', label: '7XL' }
]

export const columnsOptions = [
  { label: '1', value: 'grid-cols-1' },
  { label: '2', value: 'grid-cols-1 md:grid-cols-2' },
  { label: '3', value: 'grid-cols-1 md:grid-cols-3' },
  { label: '4', value: 'grid-cols-2 lg:grid-cols-4' }
]

export const containerOptions = [
  { label: 'Paragraph', value: 'max-w-prose' },
  { label: 'Small', value: 'max-w-sm' },
  { label: 'Medium', value: 'max-w-md' },
  { label: 'Large', value: 'max-w-lg' },
  { label: 'XL', value: 'max-w-xl' },
  { label: '2XL', value: 'max-w-2xl' },
  { label: 'Full', value: 'max-w-container' }
]

export const verticalPaddingOptions = [
  { label: 'None', value: 'py-2' },
  { label: 'Small', value: 'py-4 md:py-8' },
  { label: 'Medium', value: 'py-8 md:py-12' },
  { label: 'Large', value: 'py-12 md:py-16' },
  { label: 'XL', value: 'py-16 md:py-24' },
  { label: '2XL', value: 'py-24 md:py-32' }
]

export const verticalMarginOptions = [
  { label: 'None', value: 'my-0' },
  { label: 'XSmall', value: 'my-2 md:my-4' },
  { label: 'Small', value: 'my-4 md:my-8' },
  { label: 'Medium', value: 'my-8 md:my-12' },
  { label: 'Large', value: 'my-12 md:my-16' },
  { label: 'XL', value: 'my-16 md:my-24' },
  { label: '2XL', value: 'my-24 md:my-32' }
]

export const textAlign = [
  { label: 'Left', value: 'text-left' },
  { label: 'Center', value: 'text-center' },
  { label: 'Right', value: 'text-right' }
]

export const aspectRatioOptions = [
  { label: '1:1', value: 'aspect-square' },
  { label: '4:3', value: 'aspect-[4/3]' },
  { label: '3:2', value: 'aspect-[3/2]' },
  { label: '16:9', value: 'aspect-video' }
]

export const richTextFeatures = [
  types.RichTextFeatures.Bold,
  types.RichTextFeatures.Italic,
  types.RichTextFeatures.Highlight,
  types.RichTextFeatures.Link
]

export const richTextFeaturesExtended = [
  ...richTextFeatures,
  types.RichTextFeatures.OrderedList,
  types.RichTextFeatures.UnorderedList,
  types.RichTextFeatures.Quote,
  types.RichTextFeatures.Heading1,
  types.RichTextFeatures.Heading2,
  types.RichTextFeatures.Heading3,
  types.RichTextFeatures.Heading4,
  types.RichTextFeatures.Heading5,
  types.RichTextFeatures.Heading6
]

/* *******************************
 GENERAL PROPS
******************************* */
export const containerWidthSideEditProps: types.ISideEditProp = {
  name: 'containerWidth',
  label: 'Container Width',
  type: types.SideEditPropType.Select,
  selectOptions: {
    display: types.OptionsDisplay.Select,
    options: containerOptions
  }
}

export const verticalMarginSideEditProps: types.ISideEditProp = {
  name: 'verticalMargin',
  label: 'Vertical Space',
  type: types.SideEditPropType.Select,
  selectOptions: {
    display: types.OptionsDisplay.Select,
    options: verticalMarginOptions
  }
}

export const verticalPaddingSideEditProps: types.ISideEditProp = {
  name: 'verticalPadding',
  label: 'Vertical Space',
  type: types.SideEditPropType.Select,
  selectOptions: {
    display: types.OptionsDisplay.Select,
    options: verticalPaddingOptions
  }
}

export const columnsSideEditProps: types.ISideEditProp = {
  name: 'columns',
  label: 'Number of Item Columns',
  type: types.SideEditPropType.Select,
  selectOptions: {
    display: types.OptionsDisplay.Select,
    options: columnsOptions
  }
}

export const heightSideEditProps: types.ISideEditProp = {
  name: 'height',
  label: 'Height',
  type: types.SideEditPropType.Select,
  selectOptions: {
    display: types.OptionsDisplay.Select,
    options: [
      { value: '', label: 'Default' },
      { value: 'min-h-fit', label: 'Small' },
      { value: 'min-h-[24rem] md:min-h-[32rem]', label: 'Medium' },
      { value: 'min-h-[32rem] md:min-h-[42rem]', label: 'Large' },
      { value: 'md:min-h-[50vh]', label: 'Half Screen' },
      { value: 'min-h-[75vh]', label: '3/4 Screen' },
      { value: 'min-h-[95vh]', label: 'Full Screen' }
    ]
  }
}

export const imageWidthSideEditProps: types.ISideEditProp = {
  name: 'imageWidth',
  label: 'Image Width',
  type: types.SideEditPropType.Select,
  selectOptions: {
    display: types.OptionsDisplay.Select,
    options: [
      { value: 'w-full', label: 'Fill Space' },
      { value: 'w-full -md:ml-[10%] -md:mr-[10%]', label: 'Breakout' }
    ]
  }
}

export const imageSplitSideEditProps: types.ISideEditProp = {
  name: 'imageSplit',
  label: 'Image Split Proportions',
  type: types.SideEditPropType.Select,
  selectOptions: {
    display: types.OptionsDisplay.Select,
    options: [
      { value: 'even', label: 'Even' },
      { value: 'smaller', label: 'Smaller Image' },
      { value: 'larger', label: 'Larger Image' }
    ]
  }
}

/* *******************************
 TYPOGRAPHY PROPS
******************************* */
export const textAlignSideEditProps: types.ISideEditProp = {
  name: 'textAlign',
  label: 'Text Align',
  type: types.SideEditPropType.Select,
  selectOptions: {
    display: types.OptionsDisplay.Select,
    options: textAlign
  }
}

export const headlineSizeSideEditProps: types.ISideEditProp = {
  name: 'headlineSize',
  label: 'Headline Size',
  type: types.SideEditPropType.Select,
  selectOptions: {
    display: types.OptionsDisplay.Select,
    options: sizes
  }
}

/* *******************************
 BACKGROUND IMAGE PROPS
******************************* */
export const bgImageSideEditProps: types.ISideEditProp = {
  name: 'bgImage',
  label: 'Background Image (Optional)',
  type: types.SideEditPropType.Image,
  imageOptions: {
    maxWidth: 4096
  }
}

export const bgImageBlendModeSideEditProps: types.ISideEditProp = {
  name: 'bgImageBlendMode',
  label: 'Background Blend Mode',
  type: types.SideEditPropType.Select,
  selectOptions: {
    display: types.OptionsDisplay.Select,
    options: blendModes
  }
}

export const bgOpacitySideEditProps: types.ISideEditProp = {
  name: 'bgOpacity',
  label: 'Background Opacity',
  type: types.SideEditPropType.Range,
  rangeOptions: {
    min: 0,
    max: 1,
    step: 0.1
  }
}

export const bgColorSideEditProps: types.ISideEditProp = {
  name: 'bgColor',
  label: 'Background Color',
  type: types.SideEditPropType.Select,
  selectOptions: {
    display: types.OptionsDisplay.Color,
    options: bgColorOptions
  }
}

/* *******************************
 BACKGROUND GRADIENT PROPS
******************************* */
export const hasGradientSideEditProps: types.ISideEditProp = {
  name: 'hasGradient',
  label: 'Use Background Gradient (Optional)',
  type: types.SideEditPropType.Boolean
}

export const gradientFromColorSideEditProps: types.ISideEditProp = {
  name: 'gradientFrom',
  label: 'Background Gradient Iner Color',
  type: types.SideEditPropType.Select,
  selectOptions: {
    display: types.OptionsDisplay.Color,
    options: gradientFromColorOptions
  }
}

export const gradientToColorSideEditProps: types.ISideEditProp = {
  name: 'gradientTo',
  label: 'Background Gradient Out Color',
  type: types.SideEditPropType.Select,
  selectOptions: {
    display: types.OptionsDisplay.Color,
    options: gradientToColorOptions
  }
}

// All BG Images at once, spread over brick
export const bgGradientSideEditProps: types.ISideEditProp[] = [
  hasGradientSideEditProps,
  gradientFromColorSideEditProps,
  gradientToColorSideEditProps
]

/* *******************************
 COLOR PROPS
******************************* */
export const headlineColorSideEditProps: types.ISideEditProp = {
  name: 'headlineColor',
  label: 'Headline Color',
  type: types.SideEditPropType.Select,
  selectOptions: {
    display: types.OptionsDisplay.Color,
    options: textColorOptions
  }
}

export const subheadColorSideEditProps: types.ISideEditProp = {
  name: 'subheadColor',
  label: 'Subhead Color',
  type: types.SideEditPropType.Select,
  selectOptions: {
    display: types.OptionsDisplay.Color,
    options: textColorOptions
  }
}

export const textColorSideEditProps: types.ISideEditProp = {
  name: 'textColor',
  label: 'Text Color',
  type: types.SideEditPropType.Select,
  selectOptions: {
    display: types.OptionsDisplay.Color,
    options: textColorOptions
  }
}

export const buttonColorSideEditProps: types.ISideEditProp = {
  name: 'buttonColor',
  label: 'Button Color',
  type: types.SideEditPropType.Select,
  selectOptions: {
    display: types.OptionsDisplay.Color,
    options: btnColorOptions
  }
}

/* *******************************
 BUTTON PROPS
******************************* */
export const btnWidthEditProps: types.ISideEditProp = {
  name: 'btnWidth',
  label: 'Button Width',
  type: types.SideEditPropType.Select,
  selectOptions: {
    display: types.OptionsDisplay.Select,
    options: [
      { label: 'normal', value: 't' },
      { label: 'wide', value: 'btn-wide' },
      { label: 'full', value: 'btn-block' }
    ]
  }
}

/* *******************************
 MISC PROPS
******************************* */
export const flipSideEditProps: types.ISideEditProp = {
  name: 'flip',
  label: 'Flip Image / Text',
  type: types.SideEditPropType.Boolean
}

export const maskSideEditProps: types.ISideEditProp = {
  name: 'imageMask',
  label: 'Image Mask Shape',
  type: types.SideEditPropType.Select,
  selectOptions: {
    display: types.OptionsDisplay.Select,
    options: maskOptions
  }
}

export const aspectRatioEditProps: types.ISideEditProp = {
  name: 'aspectRatio',
  label: 'Aspect Ratio',
  type: types.SideEditPropType.Select,
  selectOptions: {
    display: types.OptionsDisplay.Select,
    options: aspectRatioOptions
  }
}
