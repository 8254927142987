export const getFontSizeClass = (size: string | undefined) => {
  if (!size) return 'text-3xl sm:text-4xl'

  let sizeClass = ''
  switch (size) {
    case 'sm':
      sizeClass = 'text-xs md:text-base'
      break
    case 'md':
      sizeClass = 'text-sm md:text-md'
      break
    case 'lg':
      sizeClass = 'text-md md:text-lg'
      break
    case 'xl':
      sizeClass = 'text-lg md:text-xl'
      break
    case '2xl':
      sizeClass = 'text-xl md:text-2xl'
      break
    case '3xl':
      sizeClass = 'text-2xl md:text-3xl'
      break
    case '4xl':
      sizeClass = 'text-3xl md:text-4xl'
      break
    case '5xl':
      sizeClass = 'text-4xl md:text-5xl'
      break
    case '6xl':
      sizeClass = 'text-5xl md:text-6xl'
      break
    case '7xl':
      sizeClass = 'text-6xl md:text-7xl'
      break
    case '8xl':
      sizeClass = 'text-7xl md:text-8xl'
      break
    default:
      sizeClass = 'text-3xl sm:text-4xl'
  }

  return sizeClass
}
