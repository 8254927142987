import clsx from 'clsx'
import type { types } from 'react-bricks/frontend'
import { Repeater } from 'react-bricks/frontend'

import { BackgroundImage } from '~/components/BackgroundImage'
import { Container } from '~/components/Container'
import type { ColorValue, ReactBricksImage } from '~/react-bricks/brick-types'
import { Copy, Headline, Subhead } from '~/react-bricks/bricks/atoms'
import {
  bgColorSideEditProps,
  bgImageBlendModeSideEditProps,
  bgImageSideEditProps,
  bgOpacitySideEditProps,
  headlineColorSideEditProps,
  subheadColorSideEditProps,
  textColorSideEditProps
} from '~/react-bricks/layoutSideProps'

//=============================
// Local Types
//=============================
type TeamGridProps = {
  bgImage?: ReactBricksImage
  bgImageBlendMode?: string
  bgOpacity?: number
  bgColor?: ColorValue
  textColor?: ColorValue
  headlineColor?: ColorValue
  subheadColor?: ColorValue
}

//=============================
// Brick
//=============================
const TeamGrid: types.Brick<TeamGridProps> = ({
  bgImage,
  bgImageBlendMode,
  bgOpacity,
  bgColor,
  headlineColor,
  subheadColor,
  textColor
}) => {
  return (
    <section className={clsx(bgColor?.className, textColor?.className, 'relative')}>
      {bgImage && (
        <BackgroundImage
          bgImage={bgImage}
          bgImageBlendMode={bgImageBlendMode}
          bgOpacity={bgOpacity}
        />
      )}
      <Container className="relative z-[1] py-24 sm:py-32">
        <div className="items-center text-center">
          <Subhead name="subhead" color={subheadColor} />
          <Headline name="headline" color={headlineColor} />
          <Container size="max-w-prose">
            <Copy name="copy" color={textColor} className="mt-4" />
          </Container>
        </div>
        <Repeater
          propName="team-avatar"
          renderWrapper={(items) => <ul className="flex flex-wrap justify-center mt-8">{items}</ul>}
        />
      </Container>
    </section>
  )
}

//=============================
// Brick Schema
//=============================
TeamGrid.schema = {
  name: 'team-grid',
  label: 'Team Member Grid',
  getDefaultProps: () => ({
    headline: 'Lorem ipsum dolor sit amet',
    subhead: 'Aenean ac est',
    copy: 'Nam tincidunt diam non libero consequat, in rutrum enim aliquam. Nullam quis diam commodo, posuere orci non, imperdiet tortor.',
    'team-avatar': [
      {
        name: 'Kokuchuu Mokshii-Okard',
        position: 'Psychotherapist / Healer / Spirit Guide'
      },
      {
        name: 'Kokuchuu Mokshii-Okard',
        position: 'Psychotherapist / Healer / Spirit Guide'
      },
      {
        name: 'Kokuchuu Mokshii-Okard',
        position: 'Psychotherapist / Healer / Spirit Guide'
      }
    ]
  }),
  repeaterItems: [
    {
      name: 'team-avatar',
      itemType: 'team-avatar',
      itemLabel: 'Team Member',
      min: 0,
      max: 15
    }
  ],
  sideEditProps: [
    {
      groupName: 'Settings',
      defaultOpen: true,
      props: []
    },
    {
      groupName: 'Backgroud Image',
      defaultOpen: false,
      props: [bgImageSideEditProps, bgImageBlendModeSideEditProps, bgOpacitySideEditProps]
    },
    {
      groupName: 'Colors',
      defaultOpen: true,
      props: [
        bgColorSideEditProps,
        subheadColorSideEditProps,
        headlineColorSideEditProps,
        textColorSideEditProps
      ]
    }
  ]
}

export default TeamGrid
