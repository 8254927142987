import clsx from 'clsx'
import type { types } from 'react-bricks/frontend'
import { Repeater } from 'react-bricks/frontend'

import { BackgroundImage } from '~/components/BackgroundImage'
import { Container } from '~/components/Container'
import type { ColorValue, ReactBricksImage } from '~/react-bricks/brick-types'
import { Headline, Subhead } from '~/react-bricks/bricks/atoms'
import {
  bgColorSideEditProps,
  bgImageBlendModeSideEditProps,
  bgImageSideEditProps,
  bgOpacitySideEditProps,
  headlineColorSideEditProps,
  headlineSizeSideEditProps,
  subheadColorSideEditProps
} from '~/react-bricks/layoutSideProps'

//=============================
// Local Types
//=============================
interface SlimCTAProps {
  bgImage?: ReactBricksImage
  bgImageBlendMode?: string
  bgOpacity?: number
  bgColor?: ColorValue
  headlineSize?: string
  subheadColor?: ColorValue
  headlineColor?: ColorValue
}

//=============================
// Brick
//=============================
const SlimCTA: types.Brick<SlimCTAProps> = ({
  bgImage,
  bgImageBlendMode,
  bgOpacity,
  bgColor,
  headlineSize,
  subheadColor,
  headlineColor
}) => {
  return (
    <section className={clsx(bgColor?.className ?? '', 'relative w-full')}>
      {bgImage && (
        <div aria-hidden="true" className="absolute inset-0 w-full h-full overflow-hidden">
          <BackgroundImage
            bgImage={bgImage}
            bgImageBlendMode={bgImageBlendMode}
            bgOpacity={bgOpacity}
          />
        </div>
      )}
      <div className="relative flex items-center justify-between py-16 sm:py-32">
        <Container className="grid w-full grid-cols-1 items-center gap-x-36 lg:grid-cols-[2fr_1fr]">
          <div className="text-center md:text-left">
            <Headline color={headlineColor} size={headlineSize} className="w-full mb-2" />
            <Subhead color={subheadColor} overrideClasses="text-2xl font-semibold" />
          </div>
          <Repeater
            propName="cta-buttons"
            renderWrapper={(items) => (
              <div className={clsx('mt-12 flex justify-center space-x-4 md:mt-0 md:justify-end')}>
                {items}
              </div>
            )}
          />
        </Container>
      </div>
    </section>
  )
}

//=============================
// Brick Schema
//=============================
SlimCTA.schema = {
  name: 'slim-cta',
  label: 'Slim CTA',
  getDefaultProps: () => ({
    ctaLink: '/',
    headlineSize: '3xl',
    bgOpacity: 1
  }),
  repeaterItems: [
    {
      name: 'cta-buttons',
      itemType: 'cta-button',
      itemLabel: 'CTA Button',
      min: 1,
      max: 2
    }
  ],
  sideEditProps: [
    {
      groupName: 'Background Image',
      defaultOpen: false,
      props: [bgImageSideEditProps, bgImageBlendModeSideEditProps, bgOpacitySideEditProps]
    },
    {
      groupName: 'Settings',
      defaultOpen: false,
      props: [headlineSizeSideEditProps]
    },
    {
      groupName: 'Colors',
      defaultOpen: true,
      props: [bgColorSideEditProps, subheadColorSideEditProps, headlineColorSideEditProps]
    }
  ]
}

export default SlimCTA
