import clsx from 'clsx'
import { Image } from 'react-bricks'
import type { types } from 'react-bricks/frontend'

import { BackgroundImage } from '~/components/BackgroundImage'
import type { ColorValue, ReactBricksImage } from '~/react-bricks/brick-types'
import { Headline, Subhead } from '~/react-bricks/bricks/atoms'
import {
  bgColorSideEditProps,
  bgImageBlendModeSideEditProps,
  bgImageSideEditProps,
  bgOpacitySideEditProps,
  headlineColorSideEditProps,
  headlineSizeSideEditProps,
  subheadColorSideEditProps
} from '~/react-bricks/layoutSideProps'

//=============================
// Local Types
//=============================
type TestimonialProps = {
  bgImage?: ReactBricksImage
  bgImageBlendMode?: string
  bgOpacity?: number
  bgColor?: ColorValue
  headlineSize?: string
  subheadColor?: ColorValue
  headlineColor?: ColorValue
}

//=============================
// Brick
//=============================
const Testimonial: types.Brick<TestimonialProps> = ({
  bgImage,
  bgImageBlendMode,
  bgOpacity,
  bgColor,
  headlineSize,
  subheadColor,
  headlineColor
}: TestimonialProps) => {
  return (
    <section
      className={clsx(
        bgColor?.className ?? '',
        'relative w-full overflow-hidden py-12 md:py-20 lg:py-24'
      )}
    >
      {bgImage && (
        <div aria-hidden="true" className="absolute inset-0 h-full w-full overflow-hidden">
          <BackgroundImage
            bgImage={bgImage}
            bgImageBlendMode={bgImageBlendMode}
            bgOpacity={bgOpacity}
          />
        </div>
      )}
      <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="relative">
          <div className="w-full">
            <Image
              propName="icon"
              alt="icon"
              aria-hidden={true}
              imageClassName="w-10 h-auto mx-auto block"
              renderWrapper={({ children, width, height }) => (
                <div className={`mx-auto mb-6 w-full aspect-[${width}/${height}]`}>{children}</div>
              )}
            />
          </div>
          <blockquote className="">
            <div className="mx-auto max-w-3xl text-center text-2xl font-medium leading-9 text-gray-900">
              <Headline
                color={headlineColor}
                size={headlineSize}
                className="mb-2 w-full"
                placeholder="Add your quote here"
              />
            </div>
            <footer className="mt-8">
              <div className="md:flex md:items-center md:justify-center">
                <Image
                  propName="avatar"
                  alt="avatar for quote"
                  imageClassName="w-10 h-10 mx-auto rounded-full"
                  renderWrapper={({ children }) => (
                    <div className="md:flex-shrink-0">{children}</div>
                  )}
                />
                <Subhead
                  color={subheadColor}
                  overrideClasses="mt-3 font-bold text-center md:mt-0 md:ml-4 md:flex md:items-center min-w-[5rem]"
                  placeholder="- Attribution"
                />
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
  )
}

//=============================
// Brick Schema
//=============================
Testimonial.schema = {
  name: 'testimonial',
  label: 'Testimonial',
  getDefaultProps: () => ({
    headline:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vel metus eget tellus interdum convallis. Phasellus accumsan, nibh vitae semper maximus, turpis lectus tempor urna, at placerat felis nisi sit amet ligula. Phasellus nisl erat, volutpat sit amet magna eget, laoreet luctus mauris.',
    subhead: 'Kokichuu Mokshii Okard'
  }),
  sideEditProps: [
    {
      groupName: 'Background Image',
      defaultOpen: false,
      props: [bgImageSideEditProps, bgImageBlendModeSideEditProps, bgOpacitySideEditProps]
    },
    {
      groupName: 'Settings',
      defaultOpen: false,
      props: [headlineSizeSideEditProps]
    },
    {
      groupName: 'Colors',
      defaultOpen: true,
      props: [bgColorSideEditProps, headlineColorSideEditProps, subheadColorSideEditProps]
    }
  ]
}

export default Testimonial
