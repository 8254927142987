import { Text, types } from 'react-bricks/frontend'

import { RemixLink } from '~/react-bricks/bricks/atoms'

//=============================
// Local Types
//=============================
export type HeaderLinkProps = {
  link?: string
  index?: number
}

//=============================
// Brick
//=============================
export const HeaderLink = ({ link }: HeaderLinkProps) => {
  return (
    <div className="relative text-center transition-opacity overflow-hiddencursor-pointer group whitespace-nowrap hover:opacity-90">
      <RemixLink href={link || '#'} className="cursor-pointer" activeClassName="font-semibold">
        <Text
          renderBlock={(props) => <span className="w-full cursor-pointer">{props.children}</span>}
          placeholder="Label"
          propName="label"
        />
        <span
          aria-hidden
          className="absolute inset-x-0 -bottom-1 h-[2px] origin-left scale-x-0 bg-black transition-transform group-hover:scale-x-100"
        ></span>
      </RemixLink>
    </div>
  )
}

//=============================
// Brick Schema
//=============================
HeaderLink.schema = {
  name: 'navlink',
  label: 'Nav Link',
  getDefaultProps: () => ({
    label: 'Lorem Ipsum',
    link: '/#',
    className: ''
  }),
  hideFromAddMenu: true,
  sideEditProps: [
    {
      name: 'link',
      label: 'Link',
      type: types.SideEditPropType.Text
    }
  ]
}
