import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoLinkedin,
  IoLogoTwitter,
  IoLogoYoutube
} from 'react-icons/io'

type SocialIconProps = {
  icon?: String | string
  className?: string
}

export const SocialIcon = ({ icon, className }: SocialIconProps) => {
  switch (icon) {
    case 'Facebook':
      return <IoLogoFacebook className={className} />
    case 'Instagram':
      return <IoLogoInstagram className={className} />
    case 'Twitter':
      return <IoLogoTwitter className={className} />
    case 'Youtube':
      return <IoLogoYoutube className={className} />
    case 'LinkedIn':
      return <IoLogoLinkedin className={className} />
    default:
      return <></>
  }
}
