import clsx from 'clsx'
import { RichText, types } from 'react-bricks/frontend'

import type { ColorValue } from '~/react-bricks/brick-types'
import { textColorOptions } from '~/react-bricks/colors'
import { richTextFeatures } from '~/react-bricks/layoutSideProps'

//=============================
// Local Types
//=============================
type StatProps = {
  index: number
  className?: string
  statFontClass?: string
  statColor: ColorValue
  statLabelColor: ColorValue
}

//=============================
// Brick
//=============================
export const Stat: types.Brick<StatProps> = ({
  className,
  statColor,
  statLabelColor,
  statFontClass = 'text-3xl md:text-4xl',
  index
}) => {
  return (
    <div key={index ?? ''} className={clsx(className, 'flex flex-col justify-center')}>
      <RichText
        renderBlock={(props) => (
          <p
            key={index}
            className={clsx(
              statFontClass,
              statColor?.className ?? 'text-inherit',
              'block font-bold'
            )}
          >
            {props.children}
          </p>
        )}
        placeholder="Stat..."
        propName="stat"
        allowedFeatures={richTextFeatures}
      />
      <RichText
        renderBlock={(props) => (
          <p className={clsx(statLabelColor?.className ?? 'text-inherit', 'mt-1 text-base')}>
            {props.children}
          </p>
        )}
        placeholder="Type your copy..."
        propName="statCopy"
        allowedFeatures={richTextFeatures}
      />
    </div>
  )
}

//=============================
// Brick Schema
//=============================
Stat.schema = {
  name: 'stat',
  label: 'Stat',
  getDefaultProps: () => ({
    stat: '100%',
    statCopy: 'Lorem ipsum dolor'
  }),
  hideFromAddMenu: true,
  sideEditProps: [
    {
      groupName: 'Colors',
      defaultOpen: true,
      props: [
        {
          name: 'statColor',
          label: 'Stat Color',
          type: types.SideEditPropType.Select,
          selectOptions: {
            display: types.OptionsDisplay.Color,
            options: textColorOptions
          }
        },
        {
          name: 'statLabelColor',
          label: 'Stat Label Color',
          type: types.SideEditPropType.Select,
          selectOptions: {
            display: types.OptionsDisplay.Color,
            options: textColorOptions
          }
        }
      ]
    }
  ]
}
