import clsx from 'clsx'
import type { types } from 'react-bricks/frontend'
import { Repeater } from 'react-bricks/frontend'

import { BackgroundImage } from '~/components/BackgroundImage'
import { Container } from '~/components/Container'
import type { ColorValue, ReactBricksImage } from '~/react-bricks/brick-types'
import { Copy, Headline, Subhead } from '~/react-bricks/bricks/atoms'
import {
  bgColorSideEditProps,
  bgImageBlendModeSideEditProps,
  bgImageSideEditProps,
  bgOpacitySideEditProps,
  headlineColorSideEditProps,
  headlineSizeSideEditProps,
  subheadColorSideEditProps,
  textColorSideEditProps
} from '~/react-bricks/layoutSideProps'

//=============================
// Local Types
//=============================
interface HeroImageProps {
  bgImage?: ReactBricksImage
  bgImageBlendMode?: string
  bgOpacity?: number
  bgColor?: ColorValue
  headlineSize?: string
  subheadColor?: ColorValue
  headlineColor?: ColorValue
  textColor?: ColorValue
}

//=============================
// Brick
//=============================
const HeroImage: types.Brick<HeroImageProps> = ({
  bgImage,
  bgImageBlendMode,
  bgOpacity,
  bgColor,
  headlineSize,
  subheadColor,
  headlineColor,
  textColor
}) => {
  const statFontClass = 'text-4xl md:text-6xl'

  return (
    <section
      className={clsx(
        bgColor?.className ?? '',
        textColor?.className ?? '',
        'relative min-h-[75vh] w-full'
      )}
    >
      <div
        aria-hidden="true"
        className="absolute inset-0 h-full min-h-[75vh] w-full overflow-hidden"
      >
        {bgImage && (
          <BackgroundImage
            bgImage={bgImage}
            bgImageBlendMode={bgImageBlendMode}
            bgOpacity={bgOpacity}
          />
        )}
      </div>
      <div className="relative flex items-center justify-center py-32 text-center sm:py-64">
        <Container size="max-w-5xl" className="flex flex-col w-full text-center">
          <Subhead color={subheadColor} className="mb-4 text-center" />
          <Headline color={headlineColor} size={headlineSize} className="w-full text-center" />
          <div>
            <Container size="max-w-prose" className="block !px-0">
              <Copy color={textColor} className="block w-full mt-4 text-center" />
            </Container>
          </div>
          <Repeater
            propName="stats"
            itemProps={{
              statFontClass,
              className: 'basis-1/2 md:basis-1/4 mb-8 text-center px-4'
            }}
            renderWrapper={(items) => (
              <div
                className={clsx(
                  'mt-10 flex w-full flex-wrap items-center justify-center text-center'
                )}
              >
                {items}
              </div>
            )}
          />
          <Repeater
            propName="cta-buttons"
            renderWrapper={(items) => (
              <div
                className={clsx(
                  'mt-12 flex flex-wrap justify-center space-y-4 md:space-x-4 md:space-y-0'
                )}
              >
                {items}
              </div>
            )}
          />
        </Container>
      </div>
    </section>
  )
}

//=============================
// Brick Schema
//=============================
HeroImage.schema = {
  name: 'hero-image',
  label: 'Hero Image',
  getDefaultProps: () => ({
    ctaLink: '/',
    headline: 'Lorem Ipsum Dolor Sit Amet',
    subhead:
      'Vestibulum vel metus eget tellus interdum convallis. Phasellus accumsan, nibh vitae semper maximus, turpis lectus tempor urna, at placerat felis nisi sit amet ligula.',
    headlineSize: '5xl',
    bgOpacity: 1
  }),
  repeaterItems: [
    {
      name: 'cta-buttons',
      itemType: 'cta-button',
      itemLabel: 'CTA Button',
      min: 0,
      max: 2
    },
    {
      name: 'stats',
      itemType: 'stat',
      itemLabel: 'Stat',
      min: 0,
      max: 9
    }
  ],
  sideEditProps: [
    {
      groupName: 'Image',
      defaultOpen: false,
      props: [bgImageSideEditProps, bgImageBlendModeSideEditProps, bgOpacitySideEditProps]
    },
    {
      groupName: 'Settings',
      defaultOpen: false,
      props: [headlineSizeSideEditProps]
    },
    {
      groupName: 'Colors',
      defaultOpen: true,
      props: [
        bgColorSideEditProps,
        subheadColorSideEditProps,
        headlineColorSideEditProps,
        textColorSideEditProps
      ]
    }
  ]
}

export default HeroImage
