import clsx from 'clsx'
import type { types } from 'react-bricks/frontend'
import { RichText } from 'react-bricks/frontend'

import type { ColorValue } from '~/react-bricks/brick-types'
import {
  bgColorSideEditProps,
  richTextFeatures,
  textColorSideEditProps
} from '~/react-bricks/layoutSideProps'

//=============================
// Local Types
//=============================
type HeaderBannerProps = {
  bgColor?: ColorValue
  textColor?: ColorValue
  placeholder?: string
}

//=============================
// Brick
//=============================
export const HeaderBanner: types.Brick = ({
  bgColor,
  textColor,
  placeholder = 'Type your text...'
}: HeaderBannerProps) => {
  return (
    <div className={clsx(bgColor?.className ?? '', 'relative')}>
      <div className="px-2 py-2 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="pr-16 sm:px-16 sm:text-center">
          <RichText
            renderBlock={(props) => (
              <p
                className={clsx(
                  textColor?.className ?? '',
                  'text-xs font-medium !leading-relaxed tracking-wide text-white'
                )}
              >
                {props.children}
              </p>
            )}
            placeholder={placeholder}
            propName="header-banner"
            allowedFeatures={richTextFeatures}
          />
        </div>
      </div>
    </div>
  )
}

//=============================
// Brick Schema
//=============================
HeaderBanner.schema = {
  name: 'header-banner',
  label: 'Header Banner',
  getDefaultProps: () => ({}),
  sideEditProps: [bgColorSideEditProps, textColorSideEditProps]
}
