import clsx from 'clsx'
import { Repeater, types } from 'react-bricks/frontend'

import { BackgroundImage } from '~/components/BackgroundImage'
import { Container } from '~/components/Container'
import type { ColorValue, ReactBricksImage } from '~/react-bricks/brick-types'
import { Copy, Headline } from '~/react-bricks/bricks/atoms'
import { bgColorOptions } from '~/react-bricks/colors'
import {
  bgColorSideEditProps,
  bgImageBlendModeSideEditProps,
  bgImageSideEditProps,
  bgOpacitySideEditProps,
  columnsSideEditProps,
  headlineColorSideEditProps,
  placeholderHeadline,
  placeholderSubhead,
  textColorSideEditProps
} from '~/react-bricks/layoutSideProps'

//=============================
// Local Types
//=============================
type IncentivesProps = {
  bgImage?: ReactBricksImage
  bgImageBlendMode?: string
  bgOpacity?: number
  bgColor?: ColorValue
  columns?: string
  headlineColor: ColorValue
  textColor?: ColorValue
  iconColor?: ColorValue
}

//=============================
// Brick
//=============================
const Incentives: types.Brick<IncentivesProps> = ({
  bgImage,
  bgImageBlendMode,
  bgOpacity,
  bgColor,
  columns,
  headlineColor,
  textColor,
  iconColor
}) => {
  return (
    <section className={clsx(bgColor?.className ?? '', textColor?.className ?? '', 'relative')}>
      {bgImage && (
        <BackgroundImage
          bgImage={bgImage}
          bgImageBlendMode={bgImageBlendMode}
          bgOpacity={bgOpacity}
        />
      )}
      <Container className="relative z-[1] py-24 sm:py-32">
        <div className="items-center text-center">
          <Headline name="headline" color={headlineColor} />
          <Container size="max-w-prose">
            <Copy color={textColor} className="mt-4" />
          </Container>
        </div>

        <Repeater
          propName="incentive"
          itemProps={{ iconColor }}
          renderWrapper={(items) => (
            <div
              className={clsx(
                columns,
                'mx-auto mt-12 grid max-w-sm gap-y-10 gap-x-8 sm:max-w-none lg:gap-y-16 '
              )}
            >
              {items}
            </div>
          )}
        />
      </Container>
    </section>
  )
}

//=============================
// Brick Schema
//=============================
Incentives.schema = {
  name: 'incentives',
  label: 'Incentives',
  getDefaultProps: () => ({
    headline: placeholderHeadline,
    columns: 'grid-cols-1 md:grid-cols-3',
    incentive: [
      {
        title: placeholderHeadline,
        copy: placeholderSubhead
      },
      {
        title: placeholderHeadline,
        copy: placeholderSubhead
      },
      {
        title: placeholderHeadline,
        copy: placeholderSubhead
      }
    ]
  }),
  repeaterItems: [
    {
      name: 'incentive',
      itemType: 'incentive',
      itemLabel: 'Incentive'
    }
  ],
  sideEditProps: [
    {
      groupName: 'Settings',
      defaultOpen: true,
      props: [columnsSideEditProps]
    },
    {
      groupName: 'Background Image',
      defaultOpen: false,
      props: [bgImageSideEditProps, bgImageBlendModeSideEditProps, bgOpacitySideEditProps]
    },
    {
      groupName: 'Colors',
      defaultOpen: true,
      props: [
        bgColorSideEditProps,
        headlineColorSideEditProps,
        textColorSideEditProps,
        {
          name: 'iconColor',
          label: 'Icon Color',
          type: types.SideEditPropType.Select,
          selectOptions: {
            display: types.OptionsDisplay.Color,
            options: bgColorOptions
          }
        }
      ]
    }
  ]
}

export default Incentives
