import { useLocation } from '@remix-run/react'
import { useEffect } from 'react'

import * as gtag from '~/utils/gtag.client'

export const Gtag = ({ gaTrackingId, debug }: { gaTrackingId: string; debug: boolean }) => {
  const location = useLocation()

  useEffect(() => {
    if (gaTrackingId?.length) {
      gtag.pageview(location.pathname, gaTrackingId)
    }
  }, [location, gaTrackingId])

  return (
    <>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`} />
      <script
        async
        id="gtag-init"
        dangerouslySetInnerHTML={{
          __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${gaTrackingId}', {
                  page_path: window.location.pathname,
                  debug_mode: ${!!debug}
                });
              `
        }}
      />
    </>
  )
}
