import clsx from 'clsx'
import type { types } from 'react-bricks/frontend'
import { Repeater } from 'react-bricks/frontend'

import type { ContainerSizes } from '~/components/Container'
import { Container } from '~/components/Container'
import type { ColorValue } from '~/react-bricks/brick-types'
import {
  bgColorSideEditProps,
  containerWidthSideEditProps,
  textAlignSideEditProps,
  textColorSideEditProps,
  verticalPaddingSideEditProps
} from '~/react-bricks/layoutSideProps'

//=============================
// Local Types
//=============================
type ArticleProps = {
  containerWidth?: ContainerSizes
  textAlign?: string
  bgColor?: ColorValue
  textColor?: ColorValue
  verticalPadding?: string
}

//=============================
// Brick
//=============================
const Article: types.Brick<ArticleProps> = ({
  containerWidth,
  textAlign,
  bgColor,
  textColor,
  verticalPadding
}: ArticleProps) => {
  return (
    <div className={clsx(bgColor?.className, verticalPadding)}>
      <Container size={containerWidth || 'max-w-prose'}>
        <article
          className={clsx(
            textAlign ?? 'text-left',
            textColor?.className,
            'prose-italic:text-inherit prose prose-strong:text-inherit'
          )}
        >
          <Repeater propName="content" />
        </article>
      </Container>
    </div>
  )
}

//=============================
// Brick Schema
//=============================
Article.schema = {
  name: 'article',
  label: 'Article',
  getDefaultProps: () => ({}),
  repeaterItems: [
    {
      name: 'content',
      itemLabel: 'Copy & Images',
      items: [
        { type: 'article-copy', label: 'Copy', min: 1 },
        { type: 'article-image', label: 'Image', min: 0 }
      ]
    }
  ],
  sideEditProps: [
    containerWidthSideEditProps,
    verticalPaddingSideEditProps,
    textAlignSideEditProps,
    bgColorSideEditProps,
    textColorSideEditProps
  ]
}

export default Article
