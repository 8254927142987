import clsx from 'clsx'
import type { types } from 'react-bricks/frontend'
import { Image, Repeater } from 'react-bricks/frontend'

import { Container } from '~/components/Container'
import type { ColorValue } from '~/react-bricks/brick-types'
import { Copy, Headline, Subhead } from '~/react-bricks/bricks/atoms'
import {
  bgColorSideEditProps,
  flipSideEditProps,
  headlineColorSideEditProps,
  headlineSizeSideEditProps,
  heightSideEditProps,
  imageSplitSideEditProps,
  subheadColorSideEditProps,
  textColorSideEditProps,
  verticalMarginSideEditProps
} from '~/react-bricks/layoutSideProps'

//=============================
// Local Types
//=============================
type FeatureSplitImageProps = {
  verticalMargin: string
  bgColor?: ColorValue
  subheadColor?: ColorValue
  headlineColor?: ColorValue
  headlineSize?: string
  textColor?: ColorValue
  flip?: boolean
  height?: string
  imageSplit?: string
}

//=============================
// Brick
//=============================
const FeatureSplitImage: types.Brick<FeatureSplitImageProps> = ({
  verticalMargin,
  bgColor,
  subheadColor,
  headlineColor,
  headlineSize,
  textColor,
  flip,
  height,
  imageSplit
}) => {
  const statFontClass = 'text-3xl md:text-4xl'

  let imageGridClass, copyGridClass
  switch (imageSplit) {
    case 'smaller':
      imageGridClass = 'md:col-span-5 xl:col-span-4'
      copyGridClass = 'md:col-span-7 xl:col-span-8'
      break
    case 'larger':
      imageGridClass = 'md:col-span-7 xl:col-span-8'
      copyGridClass = 'md:col-span-5 xl:col-span-4'
      break
    case 'even':
    default:
      imageGridClass = 'md:col-span-6'
      copyGridClass = 'md:col-span-6'
  }

  return (
    <section
      className={clsx(
        bgColor?.className ?? '',
        textColor?.className ?? '',
        verticalMargin,
        height,
        'grid grid-cols-1 md:h-full md:grid-cols-12'
      )}
    >
      <Image
        propName="image"
        alt="Feature Image"
        sizes="(min-width: 768px) 50vw, 100vw"
        imageClassName={clsx(imageGridClass, 'object-cover object-center w-full h-full')}
      />
      <div className={clsx(copyGridClass, flip && 'md:-order-1', 'flex items-center')}>
        <Container className="mx-auto w-full px-8 py-12 md:w-[80%]">
          <Subhead color={subheadColor} />
          <Headline color={headlineColor} size={headlineSize} />
          <Container size="max-w-prose" className="!px-0">
            <Copy color={textColor} className="mt-4" />
          </Container>
          <Repeater
            propName="stats"
            itemProps={{ statFontClass }}
            renderWrapper={(items) => (
              <div className={clsx('mt-10 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2')}>
                {items}
              </div>
            )}
          />
          <Repeater
            propName="cta-buttons"
            renderWrapper={(items) => <div className={clsx('mt-8 flex space-x-4')}>{items}</div>}
          />
        </Container>
      </div>
    </section>
  )
}

//=============================
// Brick Schema
//=============================
FeatureSplitImage.schema = {
  name: 'feature-split-image',
  label: 'Feature Split Image',
  getDefaultProps: () => ({
    subhead: 'Optional Subhead',
    headline: 'Lorem ipsum dolor sit amet',
    copy: 'Vestibulum vel metus eget tellus interdum convallis. Phasellus accumsan, nibh vitae semper maximus, turpis lectus tempor urna, at placerat felis nisi sit amet ligula.',
    height: 'min-h-fit',
    headlineSize: '4xl'
  }),
  repeaterItems: [
    {
      name: 'cta-buttons',
      itemType: 'cta-button',
      itemLabel: 'CTA Button',
      min: 0,
      max: 2
    },
    {
      name: 'stats',
      itemType: 'stat',
      itemLabel: 'Stat',
      min: 0,
      max: 6
    }
  ],
  sideEditProps: [
    {
      groupName: 'Settings',
      defaultOpen: true,
      props: [
        verticalMarginSideEditProps,
        imageSplitSideEditProps,
        heightSideEditProps,
        flipSideEditProps,
        headlineSizeSideEditProps
      ]
    },
    {
      groupName: 'Colors',
      defaultOpen: true,
      props: [
        bgColorSideEditProps,
        subheadColorSideEditProps,
        headlineColorSideEditProps,
        textColorSideEditProps
      ]
    }
  ]
}

export default FeatureSplitImage
