import clsx from 'clsx'
import { Repeater, types } from 'react-bricks/frontend'

import type { ContainerSizes } from '~/components/Container'
import { Container } from '~/components/Container'
import type { ColorValue } from '~/react-bricks/brick-types'
import { Copy, Headline, Subhead } from '~/react-bricks/bricks/atoms'
import {
  aspectRatioEditProps,
  bgColorSideEditProps,
  columnsSideEditProps,
  headlineColorSideEditProps,
  subheadColorSideEditProps,
  textColorSideEditProps,
  verticalMarginSideEditProps
} from '~/react-bricks/layoutSideProps'

//=============================
// Local Types
//=============================
type GalleryProps = {
  hideHeader?: boolean
  aspectRatio?: string
  columns?: string
  containerWidth?: ContainerSizes
  verticalMargin?: string
  bgColor?: ColorValue
  headlineColor?: ColorValue
  headlineSize?: string
  subheadColor?: ColorValue
  textColor?: ColorValue
}

//=============================
// Brick
//=============================
const Gallery: types.Brick<GalleryProps> = ({
  hideHeader,
  aspectRatio,
  containerWidth,
  columns,
  verticalMargin,
  bgColor,
  headlineColor,
  headlineSize,
  subheadColor,
  textColor
}) => {
  return (
    <section className={clsx(verticalMargin, bgColor?.className ?? '')}>
      <div className={clsx(containerWidth, 'mx-auto px-4')}>
        {!hideHeader && (
          <div className="max-w-3xl mx-auto mb-6 text-center">
            <Subhead color={subheadColor} className="mb-4" />
            <Headline color={headlineColor} size={headlineSize} className="mb-4" />
            <Container size="max-w-prose" className="!px-0">
              <Copy color={textColor} />
            </Container>
          </div>
        )}

        <Repeater
          propName="gallery-items"
          itemProps={{ aspectRatio }}
          renderWrapper={(items) => (
            <div className={clsx(columns, 'grid gap-y-4 gap-x-4 md:gap-y-8 md:gap-x-8')}>
              {items}
            </div>
          )}
        />
      </div>
    </section>
  )
}

//=============================
// Brick Schema
//=============================
Gallery.schema = {
  name: 'gallery',
  label: 'Gallery',
  getDefaultProps: () => ({
    aspectRatio: 'aspect-video',
    containerWidth: 'max-w-container',
    columns: 'grid-cols-2 lg:grid-cols-4',
    headline: 'Lorem Ipsum Dolor Sit Amet',
    subhead: 'Turpis lectus tempor urna',
    copy: 'Vestibulum vel metus eget tellus interdum convallis. Phasellus accumsan, nibh vitae semper maximus, turpis lectus tempor urna, at placerat felis nisi sit amet ligula.'
  }),
  repeaterItems: [
    {
      name: 'gallery-items',
      itemLabel: 'Gallery Items',
      items: [{ type: 'gallery-image', label: 'Add Image' }]
    }
  ],
  sideEditProps: [
    {
      groupName: 'Settings',
      defaultOpen: true,
      props: [
        { name: 'hideHeader', label: 'Hide Header', type: types.SideEditPropType.Boolean },
        {
          name: 'containerWidth',
          label: 'Container Width',
          type: types.SideEditPropType.Select,
          selectOptions: {
            display: types.OptionsDisplay.Select,
            options: [
              { label: 'Large', value: 'max-w-5xl' },
              { label: 'XL', value: 'max-w-6xl' },
              { label: '2XL', value: 'max-w-7xl' },
              { label: 'Full', value: 'max-w-container' }
            ]
          }
        },
        verticalMarginSideEditProps,
        columnsSideEditProps,
        aspectRatioEditProps
      ]
    },
    {
      groupName: 'Colors',
      defaultOpen: true,
      props: [
        bgColorSideEditProps,
        headlineColorSideEditProps,
        subheadColorSideEditProps,
        textColorSideEditProps
      ]
    }
  ]
}

export default Gallery
