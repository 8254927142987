import clsx from 'clsx'
import type { types } from 'react-bricks/frontend'
import { Image, useAdminContext } from 'react-bricks/frontend'
import Zoom from 'react-medium-image-zoom'

import { ConditionalWrapper } from '~/components/ConditionalWrapper'

//=============================
// Local Types
//=============================
type GalleryImageProps = {
  aspectRatio?: string
}

//=============================
// Brick
//=============================
const GalleryImage: types.Brick<GalleryImageProps> = ({ aspectRatio }) => {
  const { isAdmin } = useAdminContext()
  return (
    <div className={clsx(aspectRatio, 'overflow-hidden')}>
      <ConditionalWrapper condition={!isAdmin} wrapper={(children) => <Zoom>{children}</Zoom>}>
        <Image
          propName="image"
          alt="Gallery Item"
          sizes="100vw"
          imageClassName={clsx(aspectRatio, 'object-cover object-center w-full h-full')}
        />
      </ConditionalWrapper>
    </div>
  )
}

//=============================
// Brick Schema
//=============================
GalleryImage.schema = {
  name: 'gallery-image',
  label: 'GalleryImage',
  getDefaultProps: () => ({}),

  sideEditProps: []
}

export { GalleryImage }
