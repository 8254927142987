import clsx from 'clsx'
import type { types } from 'react-bricks/frontend'
import { Image } from 'react-bricks/frontend'

import type { ColorValue } from '~/react-bricks/brick-types'
import { Copy, Subhead } from '~/react-bricks/bricks/atoms'
import {
  maskSideEditProps,
  subheadColorSideEditProps,
  textColorSideEditProps
} from '~/react-bricks/layoutSideProps'

//=============================
// Local Types
//=============================
type FeatureAlternatingItemProps = {
  index: number
  subheadColor?: ColorValue
  textColor?: ColorValue
  rounded?: boolean
  imageMask?: string
}

//=============================
// Brick
//=============================
export const FeatureAlternatingItem: types.Brick<FeatureAlternatingItemProps> = ({
  index = 0,
  subheadColor,
  textColor,
  imageMask
}) => {
  return (
    <div className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center lg:gap-x-8">
      <div
        className={clsx(
          index % 2 === 0 ? 'lg:col-start-1' : 'lg:col-start-7 xl:col-start-8',
          'mt-6 lg:col-span-6 lg:row-start-1 lg:mt-0 xl:col-span-5'
        )}
      >
        <Subhead
          color={subheadColor}
          overrideClasses="mb-2 font-serif text-lg lg:text-xl font-semibold lg:mb-4 2xl:text-2xl"
        />
        <Copy color={textColor} className="mt-2" />
      </div>
      <div
        className={clsx(
          index % 2 === 0 ? 'lg:col-start-7 xl:col-start-6' : 'lg:col-start-1',
          'flex-auto lg:col-span-6 lg:row-start-1 xl:col-span-7'
        )}
      >
        <Image
          propName="itemImage"
          alt="Featured"
          sizes="(min-width: 768px) 40vw, 100vw"
          aspectRatio={1}
          imageClassName={imageMask || ''}
        />
      </div>
    </div>
  )
}

//=============================
// Brick Schema
//=============================
FeatureAlternatingItem.schema = {
  name: 'feature-alternating-item',
  label: 'Feature Item',
  hideFromAddMenu: true,
  getDefaultProps: () => ({
    subhead: 'Lorem ipsum dolor sit amet',
    copy: 'Vestibulum vel metus eget tellus interdum convallis. Phasellus accumsan, nibh vitae semper maximus, turpis lectus tempor urna, at placerat felis nisi sit amet ligula.'
  }),
  sideEditProps: [
    {
      groupName: 'Settings',
      defaultOpen: true,
      props: [maskSideEditProps]
    },
    {
      groupName: 'Colors',
      defaultOpen: true,
      props: [subheadColorSideEditProps, textColorSideEditProps]
    }
  ]
}

export default FeatureAlternatingItem
