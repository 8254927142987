import clsx from 'clsx'
import { Image, RichText, types } from 'react-bricks/frontend'

import { ConditionalWrapper } from '~/components/ConditionalWrapper'
import type { ColorValue } from '~/react-bricks/brick-types'
import { RemixLink } from '~/react-bricks/bricks/atoms'
import {
  headlineColorSideEditProps,
  richTextFeatures,
  textColorSideEditProps
} from '~/react-bricks/layoutSideProps'

//=============================
// Local Types
//=============================
type ImageCardProps = {
  headlineColor?: ColorValue
  textColor?: ColorValue
  link?: string
  aspectRatio?: number
}

//=============================
// Brick
//=============================
export const ImageCard = ({ link, headlineColor, textColor, aspectRatio = 1 }: ImageCardProps) => {
  return (
    <div>
      <ConditionalWrapper
        condition={!!link}
        wrapper={(children) => <RemixLink href={link || ''}>{children}</RemixLink>}
      >
        <>
          <div className="w-full overflow-hidden rounded-lg">
            <Image
              propName="image"
              alt="Artisan Cove Content"
              sizes="(min-width: 768px) 25vw, 45vw"
              imageClassName="object-cover object-center w-full h-full aspect-square"
              aspectRatio={aspectRatio}
            />
          </div>
          <div className="mt-4">
            <RichText
              renderBlock={(props) => (
                <p
                  className={clsx(
                    headlineColor?.className ?? 'text-inherit',
                    'mb-2 text-xl font-semibold'
                  )}
                >
                  {props.children}
                </p>
              )}
              placeholder="Title..."
              propName="title"
              allowedFeatures={richTextFeatures}
            />
            <RichText
              renderBlock={(props) => (
                <div className={clsx(textColor?.className ?? 'text-inherit', 'text-base')}>
                  {props.children}
                </div>
              )}
              placeholder="Copy..."
              propName="copy"
              allowedFeatures={richTextFeatures}
            />
          </div>
        </>
      </ConditionalWrapper>
    </div>
  )
}

//=============================
// Brick Schema
//=============================
ImageCard.schema = {
  name: 'image-card',
  label: 'Image Card',
  getDefaultProps: () => ({
    title: 'Lorem ipsum dolor sit amet',
    copy: 'Aenean ac est sit amet dui mattis porta ut eu quam. Nam tincidunt diam non libero consequat, in rutrum enim aliquam.'
  }),
  hideFromAddMenu: true,
  sideEditProps: [
    {
      name: 'link',
      label: 'Link (Optional)',
      type: types.SideEditPropType.Text
    },
    {
      groupName: 'Colors',
      defaultOpen: true,
      props: [headlineColorSideEditProps, textColorSideEditProps]
    }
  ]
}
