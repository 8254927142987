import type { Colors } from '~/react-bricks/brick-types'

import { DAISY_THEME_COLORS, THEME_COLORS } from '../../tailwind-colors'

/* 
  Makes Tailwind colros
*/
const makeColorOptions = (property: string): Colors => {
  const themeColors = THEME_COLORS
  const colors = []
  for (const [key, v] of Object.entries(themeColors)) {
    if (!key.includes('-')) {
      continue
    }
    const temp = {
      label: key,
      value: {
        color: v,
        className: `${property}-${key}`
      }
    }
    colors.push(temp)
  }
  const white = {
    label: 'white',
    value: {
      color: '#ffffff',
      className: `${property}-white`
    }
  }
  colors.push(white)
  return colors
}

/*
 Makes Daisy UI colors 
*/
const makeButtonColorOptions = (property: string): Colors => {
  const themeColors = DAISY_THEME_COLORS
  const colors = []
  for (const [key, v] of Object.entries(themeColors)) {
    const temp = {
      label: key,
      value: {
        color: v,
        className: `${property}-${key}`
      }
    }
    colors.push(temp)
  }
  return colors
}

export const bgColorOptions = makeColorOptions('bg')
export const textColorOptions = makeColorOptions('text')
export const gradientFromColorOptions = makeColorOptions('from')
export const gradientToColorOptions = makeColorOptions('to')
export const btnColorOptions = makeButtonColorOptions('btn')
