import {
  Links,
  LiveReload,
  Meta,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useNavigate
} from '@remix-run/react'
import type { ReactNode } from 'react'
import { ReactBricks } from 'react-bricks'

import { Gtag } from '~/components/Gtag'
import { MetaBase, MetaFavicon } from '~/components/meta'
import config from '~/react-bricks/config'
import type { loader } from '~/root'

type DocumentProps = {
  children: ReactNode
  title?: string
}

export const Document = ({ children, title }: DocumentProps) => {
  const navigate = useNavigate()
  const { appId, apiKey, gaTrackingId } = useLoaderData<typeof loader>()
  const isDevelopment = process.env.NODE_ENV === 'development'

  const reactBricksConfig = {
    ...config,
    appId,
    apiKey,
    navigate: (path: string) => navigate(path)
  }

  return (
    <html lang="en" data-theme="artisancove">
      <head>
        <MetaBase />
        <MetaFavicon />
        <Meta />
        {title ? <title>{title}</title> : null}
        <Links />
      </head>
      <body className="relative flex flex-col bg-base-100" id="root">
        <Gtag gaTrackingId={gaTrackingId} debug={isDevelopment} />
        <ReactBricks {...reactBricksConfig}>{children}</ReactBricks>
        <ScrollRestoration />
        <Scripts />
        {isDevelopment && <LiveReload />}
      </body>
    </html>
  )
}
