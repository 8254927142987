import type { types } from 'react-bricks/frontend'

import Article from './Article'
import { CTAButton, HeaderLink } from './atoms'
import FeatureAlternating from './FeatureAlternating'
import FeatureTwoColumn from './FeatureCards'
import FeatureSplitImage from './FeatureSplitImage'
import Gallery from './Gallery'
import { Footer, Header, HeaderBanner } from './globals'
import HeroImage from './HeroImage'
import ImageCarousel from './ImageCarousel'
import Incentives from './Incentives'
import {
  ArticleCopy,
  ArticleImage,
  FeatureAlternatingItem,
  GalleryImage,
  ImageCard,
  ImageCarouselSlide,
  ImageRepeater,
  Incentive,
  Stat,
  TeamAvatar
} from './repeaters'
import SlimCTA from './SlimCTA'
import Stats from './Stats'
import TeamGrid from './TeamGrid'
import Testimonial from './Testimonial'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const bricks: types.Brick<any>[] = [
  Article,
  ArticleCopy,
  ArticleImage,
  CTAButton,
  FeatureAlternating,
  FeatureAlternatingItem,
  FeatureSplitImage,
  FeatureTwoColumn,
  Footer,
  Gallery,
  GalleryImage,
  Header,
  HeaderBanner,
  HeaderLink,
  HeroImage,
  ImageCarousel,
  ImageCarouselSlide,
  ImageRepeater,
  Incentive,
  Incentives,
  ImageCard,
  SlimCTA,
  Stats,
  Stat,
  TeamAvatar,
  TeamGrid,
  Testimonial
]

export default bricks
