import clsx from 'clsx'
import { Repeater, types } from 'react-bricks/frontend'

import { Container } from '~/components/Container'
import type { ColorValue, ReactBricksImage } from '~/react-bricks/brick-types'
import { Headline, Subhead } from '~/react-bricks/bricks/atoms'
import {
  headlineColorSideEditProps,
  headlineSizeSideEditProps,
  subheadColorSideEditProps
} from '~/react-bricks/layoutSideProps'

//=============================
// Local Types
//=============================
type ImageCarouselSlideProps = {
  image: ReactBricksImage
  aspectRatio?: string
  className?: string
  headlineColor?: ColorValue
  headlineSize?: string
  subheadColor?: ColorValue
}

//=============================
// Brick
//=============================
export const ImageCarouselSlide: types.Brick<ImageCarouselSlideProps> = ({
  headlineColor,
  headlineSize,
  subheadColor,
  image,
  className,
  aspectRatio
}) => {
  return (
    <div className={clsx(aspectRatio, 'flex h-auto w-full items-center justify-center')}>
      <Container size="max-w-5xl" className="relative z-[1] flex w-full flex-col text-center">
        <Headline color={headlineColor} size={headlineSize} className="w-full text-center" />
        <Subhead color={subheadColor} className="mt-4 text-center" />
        <Repeater
          propName="cta-buttons"
          renderWrapper={(items) => (
            <div
              className={clsx(
                'mt-4 flex flex-wrap justify-center space-y-4 md:space-x-4 md:space-y-0'
              )}
            >
              {items}
            </div>
          )}
        />
      </Container>

      <img
        src={image?.src ?? 'https://dummyimage.com/1600x900/333/555'}
        srcSet={image?.srcSet ?? ''}
        alt={image?.alt ?? ''}
        sizes="100vw"
        className={clsx(
          className,
          aspectRatio,
          'absolute inset-0 h-auto w-full object-cover object-center'
        )}
      />
    </div>
  )
}

//=============================
// Brick Schema
//=============================
ImageCarouselSlide.schema = {
  name: 'image-carousel-slide',
  label: 'ImageCarouselSlide',
  getDefaultProps: () => ({}),
  hideFromAddMenu: true,
  repeaterItems: [
    {
      name: 'cta-buttons',
      itemType: 'cta-button',
      itemLabel: 'CTA Button',
      min: 0,
      max: 2
    }
  ],
  sideEditProps: [
    {
      name: 'image',
      label: 'Image',
      type: types.SideEditPropType.Image,
      imageOptions: {
        maxWidth: 4096
      }
    },
    {
      groupName: 'Settings',
      defaultOpen: true,
      props: [headlineSizeSideEditProps, subheadColorSideEditProps, headlineColorSideEditProps]
    }
  ]
}
