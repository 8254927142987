const checkDomain = (url: string) => {
  if (url.indexOf('//') === 0) {
    url = location.protocol + url
  }
  return url
    .toLowerCase()
    .replace(/([a-z])?:\/\//, '$1')
    .split('/')[0]
}

export const isExternalURL = (url: string): boolean => {
  return (
    (url.indexOf(':') > -1 || url.indexOf('//') > -1) &&
    checkDomain(location.href) !== checkDomain(url)
  )
}

export type utmItems = utmItem[]

export type utmItem = {
  key: string | undefined
  value: string | undefined
}

export const utmParamsFromUrl = (urlSearchParams: string) => {
  const utms: utmItems = []
  const params = new URLSearchParams(urlSearchParams)
  params.forEach((value, key) => {
    if (key.startsWith('utm_')) {
      utms.push({ key, value })
    }
  })
  return utms
}
