import { Image, RichText, types } from 'react-bricks/frontend'

import { ConditionalWrapper } from '~/components/ConditionalWrapper'
import { RemixLink } from '~/react-bricks/bricks/atoms/RemixLink'
import { richTextFeatures } from '~/react-bricks/layoutSideProps'

//=============================
// Local Types
//=============================
type TeamAvatarProps = {
  link?: string
}

//=============================
// Brick
//=============================
export const TeamAvatar = ({ link }: TeamAvatarProps) => {
  return (
    <li className="px-4 mb-12 text-center basis-1/2 md:basis-1/3">
      <ConditionalWrapper
        condition={!!link}
        wrapper={(children) => <RemixLink href={link || ''}>{children}</RemixLink>}
      >
        <>
          <Image
            propName="image"
            alt="Artisan Cove Team Member"
            sizes="(min-width: 768px) 25vw, 45vw"
            imageClassName="w-32 h-32 mx-auto rounded-full lg:h-56 lg:w-56 xl:h-72 xl:w-72"
          />
          <div className="space-y-2">
            <RichText
              renderBlock={(props) => (
                <p className="mt-3 text-base font-bold text-inherit md:text-lg">{props.children}</p>
              )}
              placeholder="Name..."
              propName="name"
              allowedFeatures={richTextFeatures}
            />
            <RichText
              renderBlock={(props) => (
                <p className="mt-1 text-xs text-inherit md:text-base">{props.children}</p>
              )}
              placeholder="Position..."
              propName="position"
              allowedFeatures={richTextFeatures}
            />
          </div>
        </>
      </ConditionalWrapper>
    </li>
  )
}

//=============================
// Brick Schema
//=============================
TeamAvatar.schema = {
  name: 'team-avatar',
  label: 'Team Avatar',
  getDefaultProps: () => ({
    name: 'Kokuchuu Mokshii-Okard',
    position: 'Psychotherapist / Healer / Spirit Guide'
  }),
  hideFromAddMenu: true,
  sideEditProps: [
    {
      name: 'link',
      label: 'Link (Optional)',
      type: types.SideEditPropType.Text
    }
  ]
}
