import clsx from 'clsx'
import { Text, types } from 'react-bricks/frontend'

import type { ColorValue } from '~/react-bricks/brick-types'
import { RemixLink } from '~/react-bricks/bricks/atoms'
import { btnWidthEditProps, buttonColorSideEditProps } from '~/react-bricks/layoutSideProps'

//=============================
// Local Types
//=============================
export type CTAButtonProps = {
  link?: string
  buttonColor?: ColorValue
  outline?: boolean
  btnWidth?: string
  className?: string
  dropShadow?: boolean
  index?: number
}

//=============================
// Brick
//=============================
export const CTAButton = ({
  link,
  buttonColor,
  outline,
  btnWidth,
  className,
  dropShadow,
  index
}: CTAButtonProps) => {
  return (
    <div
      key={index ?? ''}
      className={clsx(
        className,
        buttonColor?.className ?? 'btn-primary',
        outline && 'btn-outline',
        btnWidth || '',
        dropShadow && 'drop-shadow-xl',
        'btn min-w-[5rem] cursor-pointer whitespace-nowrap rounded-sm text-center'
      )}
    >
      <RemixLink href={link || '#'} className="cursor-pointer">
        <Text
          renderBlock={(props) => (
            <div className="w-full min-w-[5rem] cursor-pointer">{props.children}</div>
          )}
          placeholder="CTA Label"
          propName="cta-label"
        />
      </RemixLink>
    </div>
  )
}

//=============================
// Brick Schema
//=============================
CTAButton.schema = {
  name: 'cta-button',
  label: 'CTA Button',
  getDefaultProps: () => ({
    'cta-label': 'Click Here',
    link: '/#',
    className: ''
  }),
  hideFromAddMenu: true,
  sideEditProps: [
    {
      name: 'link',
      label: 'Link',
      type: types.SideEditPropType.Text
    },
    buttonColorSideEditProps,
    btnWidthEditProps,
    {
      name: 'outline',
      label: 'Use Outline Button',
      type: types.SideEditPropType.Boolean
    },
    {
      name: 'dropShadow',
      label: 'Enable Dropshadow',
      type: types.SideEditPropType.Boolean
    }
  ]
}
