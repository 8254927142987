import { types } from 'react-bricks/frontend'

import { DEFAULT_OG_IMAGE } from '~/constants'

const globals = ['footer', 'header', 'header-banner']

export const pageTypes: types.IPageType[] = [
  {
    name: 'global',
    pluralName: 'globals',
    isEntity: true,
    allowedBlockTypes: globals,
    defaultLocked: false,
    defaultStatus: types.PageStatus.Published,
    getDefaultContent: () => []
  },
  {
    name: 'page',
    pluralName: 'pages',
    excludedBlockTypes: globals,
    defaultLocked: false,
    defaultStatus: types.PageStatus.Published,
    defaultFeaturedImage: DEFAULT_OG_IMAGE,
    metaImageAspectRatio: 1.777,
    getDefaultContent: () => []
  }
]

export default pageTypes
